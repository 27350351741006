// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Cardo-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Cardo-Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Cardo-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Cardo';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Cardo';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Cardo';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 700;
  }
  
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,+DAAkD;IAClD,gBAAgB;EAClB;;EAEA;IACE,oBAAoB;IACpB,+DAAiD;IACjD,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,+DAA+C;IAC/C,gBAAgB;EAClB","sourcesContent":["@font-face {\n    font-family: 'Cardo';\n    src: url('./Cardo-Regular.ttf') format('truetype');\n    font-weight: 400;\n  }\n  \n  @font-face {\n    font-family: 'Cardo';\n    src: url('./Cardo-Italic.ttf') format('truetype');\n    font-weight: 400;\n    font-style: italic;\n  }\n  \n  @font-face {\n    font-family: 'Cardo';\n    src: url('./Cardo-Bold.ttf') format('truetype');\n    font-weight: 700;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
