import React from "react";
import { Handle, Position } from "reactflow";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Autocomplete,
  Tooltip,
  Paper,
  Typography,
} from "@mui/material";

const NodeWrapper = ({ children, title }) => (
  <Paper
    elevation={3}
    sx={{
      p: 2,
      minWidth: 400,
      backgroundColor: "background.paper",
      borderRadius: 2,
      "&:hover": {
        boxShadow: 6,
      },
    }}
  >
    <Typography variant="h6" gutterBottom sx={{ color: "primary.main" }}>
      {title}
    </Typography>
    {children}
  </Paper>
);

export const AssessmentComponentsNode = ({ data }) => {
  const assignmentGroups = data?.assignmentGroups || { Components: [] };
  const selectedValues = data?.selectedValues || { assignments: [] };
  const onValuesChange = data?.onValuesChange || (() => { });
  const loading = data?.loading || false;

  const options = Object.entries(assignmentGroups).flatMap(
    ([_, items]) => items || []
  );

  return (
    <NodeWrapper title="Assessment Components">
      <Handle type="source" position={Position.Bottom} />
      <Tooltip
        title="Select one or more assessment components"
        placement="right"
      >
        <FormControl fullWidth>
          <Autocomplete
            multiple
            options={options}
            value={selectedValues.assignments}
            onChange={(event, newValue) => {
              onValuesChange("assignments", newValue);
            }}
            getOptionLabel={(option) => option?.optionText || ""}
            renderInput={(params) => (
              <TextField {...params} label="Select Assessment Components" />
            )}
            disabled={loading}
            isOptionEqualToValue={(option, value) =>
              option?.optionValue === value?.optionValue
            }
          />
        </FormControl>
      </Tooltip>
    </NodeWrapper>
  );
};

export const StudyLevelNode = ({ data }) => {
  const userLevels = data?.userLevels || [];
  const selectedValues = data?.selectedValues || { userLevel: "" };
  const onValuesChange = data?.onValuesChange || (() => { });
  const loading = data?.loading || false;

  return (
    <NodeWrapper title="Level of Study">
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Tooltip title="User's level of study" placement="right">
        <FormControl fullWidth>
          <InputLabel>Level of study</InputLabel>
          <Select
            value={selectedValues.userLevel}
            onChange={(e) => onValuesChange("userLevel", e.target.value)}
            label="Select User Level"
            disabled={loading}
          >
            {userLevels.map((item) => (
              <MenuItem value={item.optionValue} key={item.optionValue}>
                {item.optionText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Tooltip>
    </NodeWrapper>
  );
};

export const DisciplineNode = ({ data }) => {
  const mainFields = data?.mainFields || [];
  const selectedValues = data?.selectedValues || {
    mainField: "",
    TopicField: "",
  };
  const onValuesChange = data?.onValuesChange || (() => { });
  const loading = data?.loading || false;

  return (
    <NodeWrapper title="Discipline & Topic">
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Tooltip
          title="Select a Discipline. This field is searchable."
          placement="right"
        >
          <FormControl fullWidth>
            <Autocomplete
              options={mainFields}
              getOptionLabel={(option) => option?.optionText || ""}
              value={
                mainFields.find(
                  (option) => option?.optionValue === selectedValues.mainField
                ) || null
              }
              onChange={(event, newValue) => {
                onValuesChange(
                  "mainField",
                  newValue ? newValue.optionValue : ""
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select a Discipline" />
              )}
              disabled={loading}
              isOptionEqualToValue={(option, value) =>
                option?.optionValue === value?.optionValue
              }
            />
          </FormControl>
        </Tooltip>

        {selectedValues.mainField &&
          selectedValues.mainField !== "other" &&
          selectedValues.mainField !== "none" && (
            <Tooltip
              title="Enter a topic within the selected discipline (optional)"
              placement="right"
            >
              <FormControl fullWidth>
                <TextField
                  label="Topic within the discipline (optional)"
                  variant="outlined"
                  value={selectedValues.TopicField}
                  onChange={(e) => onValuesChange("TopicField", e.target.value)}
                  disabled={loading}
                />
              </FormControl>
            </Tooltip>
          )}
      </Box>
    </NodeWrapper>
  );
};

export const GenerateNode = ({ data }) => {
  const onGenerate = data?.onGenerate || (() => { });
  const loading = data?.loading || false;

  return (
    <NodeWrapper title="Generate Assessment">
      <Handle type="target" position={Position.Top} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onGenerate}
          disabled={loading}
          fullWidth
          sx={{
            height: 48,
            fontSize: "1.1rem",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          {loading ? "Generating..." : "Generate Assessment"}
        </Button>
        {loading && (
          <Typography color="text.secondary" align="center">
            Please wait while we generate your assessment...
          </Typography>
        )}
      </Box>
    </NodeWrapper>
  );
};
