import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';
import { useAuth } from './useAuth';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import MenuIcon from '@mui/icons-material/Menu';
import '../App.css';
import { ClassNames } from '@emotion/react';

function Header({ open, toggleDrawer }) {
    const navigate = useNavigate();
    const { toggleTheme } = useContext(ThemeContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { user, logout } = useAuth();

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {user && (
                    <IconButton
                        color="inherit"
                        aria-label="toggle drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => navigate('/')}>
                    <img src="/logo.png" alt="logo" style={{ height: '40px' }} />
                </IconButton>
                <Typography variant="h6" style={{ flexGrow: 1 }} onClick={() => navigate('/')}>
                    Assessment Partner - <i className='betatag'>beta</i>
                </Typography>
                {!isMobile && (
                    <>
                        <Button color="inherit" onClick={() => navigate('/')}>Home</Button>
                        {/* <Button color="inherit" onClick={() => navigate('/select')}>Create</Button>
                        <Button color="inherit" onClick={() => navigate('/assessment/listall')}>Shared</Button> */}
                        {/* <Button color="inherit" onClick={() => navigate('/features')}>Features</Button> */}
                        <Button color="inherit" onClick={() => navigate('/feedback')}>Feedback</Button>
                        <Button color="inherit" onClick={() => navigate('/about')}>About</Button>
                        <Button color="inherit" onClick={() => navigate('/team')}>Team</Button>
                        {/* {user && <Button color="inherit" onClick={() => navigate('/dashboard')}>Dashboard</Button>} */}
                        {/* {user && user.group === 'admin' && <Button color="inherit" onClick={() => navigate('/admin')}>Admin Center</Button>} */}
                        {user ? (
                            <Button color="inherit" onClick={logout}>Logout</Button>
                        ) : (
                            <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
                        )}
                    </>
                )}
                <IconButton onClick={toggleTheme} color="inherit">
                    <Brightness4Icon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
