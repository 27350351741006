// FileUploadForm.js
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Tooltip,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import config from "../config";
import MarkdownRenderer from "./MarkdownRenderer";
import { LoadingIndicator } from "./CustomHooks";

function FileUploadForm() {
  const navigate = useNavigate();
  const resultContentsRef = useRef(null);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectTimeoutRef = useRef(null);

  // State management using structured objects
  const [formData, setFormData] = useState({
    userLevels: [],
    assessmentFormats: {},
    TypeOfAssessments: [],
    taxonomyModels: {},
  });

  const [selectedValues, setSelectedValues] = useState({
    assessmentFormat: "",
    typeOfAssessment: "",
    taxonomyModel: "",
    skillField: "",
    userLevel: "",
    TopicField: "",
    genAiUse: "none",
    gptVersion: "gpt-4-1106-preview",
  });

  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
  });

  const [uiState, setUiState] = useState({
    loading: false,
    messages: [],
    resultContents: "",
    visibleContentLength: 0,
    showSaveButton: false,
    saveButtonDisabled: false,
    token: null,
    showPrintButton: false,
    showCustomizeButton: false,
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
  });

  // WebSocket state management
  const [wsState, setWsState] = useState({
    ws: null,
    isConnected: false,
  });

  // Dropzone configuration
  const onDrop = useCallback((acceptedFiles) => {
    setFileState((prev) => ({
      ...prev,
      file: acceptedFiles[0],
      uploadStatus: "",
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  });

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setUiState((prev) => ({
          ...prev,
          messages: [data.message],
          queuePosition: data.queuePosition,
          loading: true,
          isQueued: true,
        }));
        break;

      case "processing":
        const statusMessage =
          data.message ||
          (data.status === "processing" && data.message) ||
          "Processing...";
        setUiState((prev) => ({
          ...prev,
          messages: [statusMessage],
          loading: true,
          isQueued: false,
        }));
        break;

      case "Success":
        setUiState((prev) => ({
          ...prev,
          resultContents: data.content,
          loading: false,
          token: data.token,
          messages: [], // Clear the messages when done
        }));
        break;

      case "done":
        setUiState((prev) => ({
          ...prev,
          showSaveButton: true,
          showPrintButton: true,
          showCustomizeButton: true,
          loading: false,
          messages: [], // Clear the messages when done
        }));
        break;

      default:
        if (data.error) {
          console.error("Error:", data.error);
          setUiState((prev) => ({
            ...prev,
            snackbarMessage: "An error occurred",
            snackbarSeverity: "error",
            snackbarOpen: true,
            loading: false,
            messages: [],
          }));
        }
    }
  }, []);

  // Initialize WebSocket
  const initWebSocket = React.useCallback(() => {
    if (ws) {
      ws.close();
    }

    const wsInstance = new WebSocket(`${config.appUrl}/ws`);

    wsInstance.onopen = () => {
      setIsConnected(true);
      setUiState((prev) => ({
        ...prev,
        error: null,
        snackbarOpen: false,
      }));
      console.log("WebSocket connected");
    };

    wsInstance.onmessage = handleWebSocketMessage;

    wsInstance.onclose = (event) => {
      setIsConnected(false);
      console.log("WebSocket closed:", event);

      if (!event.wasClean) {
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Connection lost. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }

      if (!window.isUnloading) {
        reconnectTimeoutRef.current = setTimeout(() => {
          console.log("Attempting to reconnect...");
          initWebSocket();
        }, 3000);
      }
    };

    wsInstance.onerror = (error) => {
      console.error("WebSocket error:", error);
      setUiState((prev) => ({
        ...prev,
        loading: false,
        snackbarMessage: "Connection error. Please try again.",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    };

    setWs(wsInstance);
  }, [handleWebSocketMessage]);

  useEffect(() => {
    window.isUnloading = false;

    initWebSocket();

    const handleBeforeUnload = () => {
      window.isUnloading = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.isUnloading = true;
      window.removeEventListener("beforeunload", handleBeforeUnload);
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (ws) {
        ws.close();
      }
    };
  }, [initWebSocket]);

  // Add page unload handler
  useEffect(() => {
    window.isUnloading = false;

    const handleBeforeUnload = () => {
      window.isUnloading = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.isUnloading = true;
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  // Data fetching
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();

      setFormData({
        userLevels: data.user_level,
        TypeOfAssessments: data.type_of_assessment,
        assessmentFormats: {
          Written: data.assessment_formats.Written,
          Oral: data.assessment_formats.Oral,
          Practical: data.assessment_formats.Practical,
          Performance: data.assessment_formats.Performance,
          "Problem based": data.assessment_formats.Problem_based,
        },
        taxonomyModels: {
          "Blooms Taxonomy": data.taxonomies.blooms_taxonomy,
          "Solo Taxonomy": data.taxonomies.solo_taxonomy,
          "Creativity Taxonomy": data.taxonomies.creativity_taxonomy,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Error fetching data",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Scroll to bottom effect
  useEffect(() => {
    if (uiState.visibleContentLength > 0 && resultContentsRef.current) {
      const scrollToBottom = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const currentPosition = window.pageYOffset;
        const targetPosition = scrollHeight - window.innerHeight;
        const distance = targetPosition - currentPosition;
        const duration = 600; // Scroll animation duration in milliseconds

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percentage = Math.min(progress / duration, 1);

          window.scrollTo(0, currentPosition + distance * percentage);

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };

        let start = null;
        window.requestAnimationFrame(step);
      };

      scrollToBottom();
    }
  }, [uiState.visibleContentLength]);

  // 2. Modify the content reveal effect (replace the existing one)
  useEffect(() => {
    if (uiState.resultContents.length > 20) {
      const revealSpeed = 8; // Adjust this to control reveal speed
      const intervalId = setInterval(() => {
        setUiState((prev) => ({
          ...prev,
          visibleContentLength: Math.min(
            prev.visibleContentLength + revealSpeed,
            prev.resultContents.length
          ),
        }));
      }, 10);
      return () => clearInterval(intervalId);
    }
  }, [uiState.resultContents]);

  // Form submission handler
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!fileState.file) {
        setFileState((prev) => ({
          ...prev,
          uploadStatus: "Please select a file to upload.",
        }));
        return;
      }

      if (!ws || !isConnected) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "No connection available. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        return;
      }

      setUiState((prev) => ({
        ...prev,
        loading: true,
        messages: ["Initializing file upload and assessment generation..."],
      }));

      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const fileContent = e.target.result;
          const formDataToSend = {
            ...selectedValues,
            formType: "document_upload",
            fileContent,
            fileName: fileState.file.name,
          };

          try {
            ws.send(JSON.stringify(formDataToSend));
          } catch (error) {
            console.error("Error sending data:", error);
            setUiState((prev) => ({
              ...prev,
              loading: false,
              snackbarMessage: "Failed to send request. Please try again.",
              snackbarSeverity: "error",
              snackbarOpen: true,
            }));
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setUiState((prev) => ({
            ...prev,
            loading: false,
            snackbarMessage: "Error reading file. Please try again.",
            snackbarSeverity: "error",
            snackbarOpen: true,
          }));
        };

        reader.readAsDataURL(fileState.file);
      } catch (error) {
        console.error("Error processing file:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Error processing file. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    },
    [fileState.file, selectedValues, ws, isConnected]
  );

  // Save and share handler
  const handleSaveAndShare = useCallback(async () => {
    try {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: true }));

      const response = await fetch(`${config.webUrl}/assessments/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: uiState.token }),
      });

      if (!response.ok) throw new Error("Failed to save assessment");

      const data = await response.json();
      console.log("Assessment saved:", data);

      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Assessment saved successfully",
        snackbarSeverity: "success",
        snackbarOpen: true,
      }));
    } catch (error) {
      console.error("Error saving assessment:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to save assessment",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    } finally {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: false }));
    }
  }, [uiState.token]);

  // Customize handler
  const handleCustomize = useCallback(async () => {
    if (uiState.token) {
      try {
        await handleSaveAndShare();
        navigate(`/assessments/list/${uiState.token}`);
      } catch (error) {
        console.error("Error saving and navigating:", error);
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and customize. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    } else {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Please generate an assessment first.",
        snackbarSeverity: "warning",
        snackbarOpen: true,
      }));
    }
  }, [uiState.token, handleSaveAndShare, navigate]);

  // Handle print
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      MarkdownRenderer.getPrintContent(uiState.resultContents)
    );
    printWindow.document.close();
    printWindow.print();
  };

  // Field change handler
  const handleFieldChange = useCallback(
    (field) => (event, newValue) => {
      setSelectedValues((prev) => ({
        ...prev,
        [field]: newValue?.optionValue ?? event.target.value,
      }));
    },
    []
  );

  // Memoized drop zone component
  const DropZoneComponent = useMemo(
    () => (
      <Paper
        {...getRootProps()}
        elevation={3}
        className="uploadurfiles"
        style={{
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: isDragActive ? "#f0f0f0" : "white",
          marginBottom: "20px",
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon style={{ fontSize: 48, marginBottom: "10px" }} />
        <Typography variant="h6">
          {isDragActive
            ? "Drop the file here"
            : "Drag 'n' drop a file here, or click to select a file"}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          (Only .pdf, .txt, and .docx files are accepted)
        </Typography>
      </Paper>
    ),
    [getRootProps, getInputProps, isDragActive]
  );

  // Memoized form fields
  const FormFields = useMemo(
    () => (
      <>
        {/* User Level Selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Level of study</InputLabel>
          <Select
            value={selectedValues.userLevel}
            onChange={handleFieldChange("userLevel")}
            label="Select User Level"
          >
            {formData.userLevels.map((item) => (
              <MenuItem value={item.optionValue} key={item.optionValue}>
                {item.optionText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Assessment Format */}
        <Tooltip title="Select the format of the assessment" placement="right">
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Assessment Format</InputLabel>
            <Select
              value={selectedValues.assessmentFormat}
              onChange={handleFieldChange("assessmentFormat")}
              label="Select Assessment Format"
              MenuProps={{
                PaperProps: { style: { maxHeight: 300 } },
              }}
            >
              {Object.entries(formData.assessmentFormats).map(
                ([group, items]) => [
                  <MenuItem
                    key={group}
                    value={group}
                    disabled
                    style={{ fontWeight: "bold" }}
                  >
                    {group}
                  </MenuItem>,
                  ...items.map((item) => (
                    <MenuItem
                      key={item.optionValue}
                      value={item.optionValue}
                      style={{ paddingLeft: "20px" }}
                    >
                      {item.optionText}
                    </MenuItem>
                  )),
                ]
              )}
            </Select>
          </FormControl>
        </Tooltip>

        {/* Topic Field */}
        <FormControl fullWidth margin="normal">
          <TextField
            label="Topic within the discipline (optional)"
            variant="outlined"
            value={selectedValues.TopicField}
            placeholder="e.g. Algebra, Geometry, etc."
            onChange={handleFieldChange("TopicField")}
          />
        </FormControl>

        {/* Skills Field */}
        <FormControl fullWidth margin="normal">
          <Tooltip
            title="Enter specific skills, competencies, or specializations"
            placement="right"
          >
            <TextField
              label="Skills | Competencies | Specialization (optional)"
              variant="outlined"
              value={selectedValues.skillField}
              placeholder="e.g. Data Analysis, Critical Thinking, etc."
              onChange={handleFieldChange("skillField")}
            />
          </Tooltip>
        </FormControl>

        {/* Type of Assessment */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Select a Type of Assessment</InputLabel>
          <Select
            value={selectedValues.typeOfAssessment}
            onChange={handleFieldChange("typeOfAssessment")}
            label="Select Type of Assessment"
          >
            {formData.TypeOfAssessments.map((item) => (
              <MenuItem value={item.optionValue} key={item.optionValue}>
                {item.optionText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Taxonomy Model */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Learning Taxonomy or Model</InputLabel>
          <Select
            value={selectedValues.taxonomyModel}
            onChange={handleFieldChange("taxonomyModel")}
            label="Learning Taxonomy or Model"
            MenuProps={{
              PaperProps: { style: { maxHeight: 300 } },
            }}
          >
            {Object.entries(formData.taxonomyModels).map(([group, items]) => [
              <MenuItem
                key={group}
                value={group}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {group}
              </MenuItem>,
              ...items.map((item) => (
                <MenuItem
                  key={item.optionValue}
                  value={item.optionValue}
                  style={{ paddingLeft: "20px" }}
                >
                  {item.optionText}
                </MenuItem>
              )),
            ])}
          </Select>
        </FormControl>

        {/* GenAI Usage */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Generative AI Usage</InputLabel>
          <Select
            value={selectedValues.genAiUse}
            onChange={handleFieldChange("genAiUse")}
            label="Generative AI Usage"
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="usegenai">Incorporate Generative AI</MenuItem>
            <MenuItem value="deterai">Mitigate use of Generative AI</MenuItem>
          </Select>
        </FormControl>

        {/* Hidden GPT Version */}
        <FormControl fullWidth margin="normal" style={{ display: "none" }}>
          <InputLabel>Select the version of LLM</InputLabel>
          <Select
            value={selectedValues.gptVersion}
            onChange={handleFieldChange("gptVersion")}
            label="Select the version of LLM"
          >
            <MenuItem value="gpt-4-1106-preview">GPT-4</MenuItem>
            <MenuItem value="gpt-3.5-turbo" disabled>
              GPT-3.5
            </MenuItem>
          </Select>
        </FormControl>
      </>
    ),
    [formData, selectedValues, handleFieldChange]
  );

  // Memoized loading component
  const LoadingComponent = useMemo(
    () => (
      <Box>
        {uiState.loading && <LoadingIndicator messages={uiState.messages} />}
      </Box>
    ),
    [uiState.loading, uiState.messages]
  );

  return (
    <>
      <Box
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "80%", margin: "0 auto" }}
      >
        <h1 className="text-3xl font-bold my-4">Assessment Partner</h1>
        <h3 className="text-xl mb-4">Assessments development with your data</h3>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <form onSubmit={handleSubmit}>
              {DropZoneComponent}

              {fileState.file && (
                <Typography variant="body1" style={{ marginBottom: "20px" }}>
                  Selected file: {fileState.file.name}
                </Typography>
              )}

              {FormFields}

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={uiState.loading || !fileState.file}
                >
                  Upload and Generate Assessment
                </Button>
              </Box>
            </form>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div className="AssesmantGenMainInfo">
              <Typography variant="h5" component="h4">
                To create a customized assessment based on your uploaded
                document, begin by uploading a PDF, TXT, or DOCX file related to
                your course content. Then, specify key topics, skills, and
                values to contextualize the assessment. Select a learning
                taxonomy and the level the assessment should target. Indicate
                whether the assessment is formative (process-focused) or
                summative (product-focused). Finally, choose whether the
                assessment should be tailored for students to utilize generative
                AI in completing the assignment or design assessment to mitigate
                the use of AI generative tools.
              </Typography>
            </div>
            {LoadingComponent}
          </Grid>
        </Grid>

        <Box>
          <Box ref={resultContentsRef} mt={2} className="resultsBox">
            <MarkdownRenderer
              content={uiState.resultContents.slice(
                0,
                uiState.visibleContentLength
              )}
            />
          </Box>

          <Box mt={2} display="flex" gap={2}>
            {uiState.showSaveButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
                disabled={uiState.saveButtonDisabled}
              >
                Save and Share
              </Button>
            )}

            {uiState.showCustomizeButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCustomize}
                disabled={!uiState.token}
              >
                Customize
              </Button>
            )}

            {uiState.showPrintButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
          severity={uiState.snackbarSeverity}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default FileUploadForm;
