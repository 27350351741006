import React, { useEffect, useRef } from "react";
import { marked } from "marked";
import katex from "katex";
import "katex/dist/katex.min.css";
import "../markdown-renderer.css";

const MarkdownRenderer = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!content || !containerRef.current) return;

    // Configure marked
    marked.setOptions({
      gfm: true,
      breaks: true,
      pedantic: false,
      smartLists: true,
      smartypants: true,
    });

    // Add KaTeX rendering support
    const renderer = new marked.Renderer();
    const originalCode = renderer.code.bind(renderer);

    renderer.code = (code, language) => {
      if (language === "math") {
        try {
          return katex.renderToString(code, {
            displayMode: true,
            throwOnError: false,
          });
        } catch (error) {
          console.error("KaTeX rendering error:", error);
          return code;
        }
      }
      return originalCode(code, language);
    };

    // Create inline math extension
    const inlineMathExtension = {
      name: "inlineMath",
      level: "inline",
      start(src) {
        return src.match(/\$/)?.index;
      },
      tokenizer(src, tokens) {
        const match = src.match(/^\$([^\$]+)\$/);
        if (match) {
          try {
            return {
              type: "html",
              raw: match[0],
              text: katex.renderToString(match[1], {
                displayMode: false,
                throwOnError: false,
              }),
            };
          } catch (error) {
            console.error("KaTeX rendering error:", error);
            return {
              type: "text",
              raw: match[0],
              text: match[0],
            };
          }
        }
        return false;
      },
    };

    marked.use({
      renderer,
      extensions: [inlineMathExtension],
    });

    // Convert markdown to HTML and render
    const html = marked(content);
    containerRef.current.innerHTML = html;
  }, [content]);

  return <div ref={containerRef} className="markdown-content" />;
};

// Static method for generating print-friendly HTML
MarkdownRenderer.getPrintContent = (content) => {
  marked.setOptions({
    gfm: true,
    breaks: true,
    pedantic: false,
    smartLists: true,
    smartypants: true,
  });

  return `
    <html>
      <head>
        <title>Print</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.16.0/dist/katex.min.css">
        <style>
          body { 
            font-family: Arial, sans-serif; 
            margin: 2rem;
          }
          .table-container { 
            overflow-x: auto; 
            margin-bottom: 1rem; 
          }
          table { 
            border-collapse: collapse; 
            width: 100%;
            min-width: 650px;
            border: 1px solid #ddd;
          }
          thead {
            background-color: #f5f5f5;
          }
          th { 
            border-bottom: 2px solid #ddd;
            border-right: 1px solid #ddd;
            padding: 12px 8px; 
            text-align: left; 
            background-color: #f5f5f5;
            font-weight: bold;
          }
          td { 
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;
            padding: 8px;
            vertical-align: top;
          }
          tr {
            border-bottom: 1px solid #ddd;
          }
          blockquote { 
            border-left: 4px solid #ddd; 
            padding-left: 1rem; 
            margin: 1rem 0;
            color: rgba(0, 0, 0, 0.7);
          }
          code { 
            background-color: #f5f5f5; 
            padding: 2px 4px; 
            border-radius: 4px; 
            font-size: 0.875em; 
          }
          pre { 
            background-color: #f5f5f5; 
            padding: 1rem; 
            border-radius: 4px; 
            overflow: auto; 
          }
          .rubrics_table {
            margin-bottom: 1rem;
          }
        </style>
      </head>
      <body class="print-content">
        ${marked(content)}
      </body>
    </html>
  `;
};

export default MarkdownRenderer;
