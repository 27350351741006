import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';

import FlexLoader from "./FlexLoader";
import DOMPurify from "dompurify";
import config from "../config";
import { marked } from "marked";
import IconButton from "@mui/material/IconButton";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import ChatDrawer from "./ChatDrawer";
import RubricsCustomizationModal from "./RubricsCustomizationModal";
import MarkdownRenderer from "./MarkdownRenderer";

function TextModal({ open, onClose, onSubmit, selectedText }) {
  const [inputText, setInputText] = useState("");

  const handleSubmit = useCallback(() => {
    onSubmit(inputText);
    onClose();
  }, [inputText, onSubmit, onClose]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      } else if (event.key === "Escape") {
        onClose();
      }
    },
    [handleSubmit, onClose]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, handleKeyDown]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Typography
          className="user_request_context"
          sx={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            marginBottom: 2,
          }}
        >
          {selectedText}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ position: "relative" }}
        >
          <TextField
            autoFocus
            margin="none"
            label="How should this be rewritten?"
            fullWidth
            multiline
            rows={3}
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            aria-label="submitAI"
            onClick={handleSubmit}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: "50%",
              transform: "translateY(-50%)",
              color: theme.palette.grey[500],
            })}
          >
            <TipsAndUpdatesIcon />
          </IconButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function ConfirmationDialog({ open, onClose, onConfirm, title, content }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AssessmentDetail() {
  const [assessment, setAssessment] = useState(null);
  const [assessmentContent, setAssessmentContent] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [alternativeResults, setAlternativeResults] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [showPrintButton, setShowPrintButton] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [customizeMode, setCustomizeMode] = useState(false);
  const [rubricEditMode, setRubricEditMode] = useState(false);
  const [rubricsCustomizationOpen, setRubricsCustomizationOpen] =
    useState(false);
  const [selectedTableIndex, setSelectedTableIndex] = useState(-1);

  const [showRubricToolbar, setShowRubricToolbar] = useState(false);
  const [rubricToolbarPosition, setRubricToolbarPosition] = useState({
    top: 0,
    left: 0,
  });
  const [selectedRubric, setSelectedRubric] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogProps, setConfirmDialogProps] = useState({});

  const [selectedRubricContent, setSelectedRubricContent] = useState("");

  const toolbarRef = useRef(null);
  const rubricHoverTimeoutRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);

  const handleDownloadClick = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
  };

  const handleDownload = async (format) => {
    try {
      setLoading(true);
      const markdownContent = document.querySelector('.assess-content');
      if (!markdownContent) {
        throw new Error('Could not find assessment content');
      }

      const content = markdownContent.innerHTML;
      
      const response = await fetch(`${config.webUrl}/assessments/download/${format}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: content,
          title: `Assessment_${id}`,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to download as ${format}`);
      }

      // Get the filename from the Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : `Assessment_${id}.${format}`;

      // Create blob from response and download
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setSnackbarMessage(`Downloaded successfully as ${format.toUpperCase()}`);
      setSnackbarSeverity('success');
    } catch (error) {
      console.error(`Error downloading as ${format}:`, error);
      setSnackbarMessage(`Failed to download as ${format.toUpperCase()}`);
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      handleDownloadClose();
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/assessments/${id}`);
        if (!response.ok) {
          throw new Error("Assessment not found");
        }
        const data = await response.json();
        setAssessment(data);
        setAssessmentContent(data.output);
        setShowPrintButton(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // For the print function, update it to:
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      MarkdownRenderer.getPrintContent(assessmentContent)
    );
    printWindow.document.close();
    printWindow.print();
  };

  const openModal = useCallback((text) => {
    setSelectedText(text);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(
    async (inputText) => {
      try {
        setLoading(true);
        closeModal();

        const response = await fetch(`${config.webUrl}/assessments/customize`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            assessmentId: id,
            selectedText: selectedText,
            userInput: inputText,
            isTable: selectedTableIndex !== -1,
            tableIndex: selectedTableIndex,
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to customize section");
        }

        const data = await response.json();
        const customizedText = data.customizedText;

        // Create a temporary DOM element
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = marked(DOMPurify.sanitize(assessmentContent));

        if (selectedTableIndex !== -1) {
          // Replace the entire table
          const tables = tempDiv.querySelectorAll("table");
          if (selectedTableIndex < tables.length) {
            const selectedTable = tables[selectedTableIndex];
            const newTable = document.createElement("div");
            newTable.innerHTML = customizedText;
            selectedTable.parentNode.replaceChild(
              newTable.firstChild,
              selectedTable
            );
          } else {
            throw new Error("Selected table not found");
          }
        } else {
          // Function to find and replace text
          const findAndReplace = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              if (node.textContent.includes(selectedText)) {
                console.log(
                  "Found selected text in text node:",
                  node.textContent
                );
                const newNode = document.createElement("span");
                newNode.innerHTML = node.textContent.replace(
                  selectedText,
                  customizedText
                );
                node.parentNode.replaceChild(newNode, node);
                return true;
              }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
              // Special handling for table cells
              if (node.tagName === "TD" || node.tagName === "TH") {
                if (node.innerHTML.includes(selectedText)) {
                  console.log(
                    "Found selected text in table cell:",
                    node.innerHTML
                  );
                  node.innerHTML = node.innerHTML.replace(
                    selectedText,
                    customizedText
                  );
                  return true;
                }
              }
              // For other elements
              if (node.innerHTML === selectedText) {
                console.log("Found exact match in element:", node.outerHTML);
                node.innerHTML = customizedText;
                return true;
              }
              for (let i = 0; i < node.childNodes.length; i++) {
                if (findAndReplace(node.childNodes[i])) {
                  return true;
                }
              }
            }
            return false;
          };

          // Perform the replacement
          if (!findAndReplace(tempDiv)) {
            console.log("Selected text not found. Attempting partial match.");
            // If exact match not found, try to find a partial match
            const partialMatch = (node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.innerHTML.includes(selectedText)) {
                  console.log(
                    "Found partial match in element:",
                    node.outerHTML
                  );
                  node.innerHTML = node.innerHTML.replace(
                    selectedText,
                    customizedText
                  );
                  return true;
                }
                for (let i = 0; i < node.childNodes.length; i++) {
                  if (partialMatch(node.childNodes[i])) {
                    return true;
                  }
                }
              }
              return false;
            };

            if (!partialMatch(tempDiv)) {
              console.log("No match found. Unable to replace content.");
              throw new Error(
                "Unable to find the selected content for replacement."
              );
            }
          }
        }

        // Update the assessment content
        setAssessmentContent(tempDiv.innerHTML);
        setEditedContent((prevContent) => prevContent + customizedText);

        setSnackbarMessage("Section customized successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error customizing section:", error);
        setSnackbarMessage("Failed to customize section. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
        setSelectedTableIndex(-1); // Reset the selected table index
      }
    },
    [id, selectedText, closeModal, assessmentContent, selectedTableIndex]
  );

  const handleTextSelect = useCallback(() => {
    if (customizeMode && (!selectedRubric || rubricEditMode)) {
      const selection = window.getSelection();
      if (selection.toString().length > 0) {
        const range = selection.getRangeAt(0);
        const selectedContent = range.cloneContents();
        const tempDiv = document.createElement("div");
        tempDiv.appendChild(selectedContent);
        const selectedHtml = tempDiv.innerHTML;

        // Check if the selection contains a table
        let tableElement = null;
        let node = range.commonAncestorContainer;

        // Traverse up the DOM tree to find a table element
        while (node && node !== document.body) {
          if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.tagName.toLowerCase() === "table"
          ) {
            tableElement = node;
            break;
          }
          node = node.parentNode;
        }

        if (tableElement) {
          // Find the index of the selected table
          const tables = document.querySelectorAll("table");
          const tableIndex = Array.from(tables).indexOf(tableElement);
          setSelectedTableIndex(tableIndex);
          setSelectedText(tableElement.outerHTML);
        } else {
          setSelectedText(selectedHtml);
          setSelectedTableIndex(-1);
        }

        openModal(selectedHtml);
      }
    }
  }, [customizeMode, rubricEditMode, selectedRubric, openModal]);

  const generateAlternative = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.webUrl}/assessments/alternative`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ assessmentId: id }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate alternative");
      }

      const data = await response.json();
      setAlternativeResults(data.alternative);
      setActiveTab(1);
    } catch (error) {
      console.error("Error generating alternative:", error);
      setSnackbarMessage("Failed to generate alternative. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveAsNew = async () => {
    try {
      // Find the markdown content element
      const markdownContent = document.querySelector('.assess-content');
      if (!markdownContent) {
        throw new Error('Could not find assessment content');
      }

      // Get the content from the markdown content element
      const content = markdownContent.innerHTML;

      const response = await fetch(`${config.webUrl}/assessments/save-as-new`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          originalId: id,
          content: content,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save as new assessment");
      }

      const data = await response.json();
      
      // Show success message
      setSnackbarMessage("Assessment saved as new successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Optionally navigate to the new assessment
      if (data.newId) {
        navigate(`/assessments/list/${data.newId}`);
      }

    } catch (error) {
      console.error("Error saving as new assessment:", error);
      setSnackbarMessage("Failed to save as new assessment");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  // RUBRIC CUSTOMIZATION
  const toggleCustomizeMode = () => {
    setCustomizeMode(!customizeMode);
    if (!customizeMode) {
      setRubricEditMode(false);
    }
  };

  const handleRubricMouseEnter = useCallback(
    (event) => {
      if (customizeMode && !rubricEditMode) {
        if (rubricHoverTimeoutRef.current) {
          clearTimeout(rubricHoverTimeoutRef.current);
        }
        const rect = event.target.getBoundingClientRect();
        setRubricToolbarPosition({
          top: rect.top + window.scrollY,
          left: rect.left + rect.width / 2,
        });
        setShowRubricToolbar(true);
        setSelectedRubric(event.target);
      }
    },
    [customizeMode, rubricEditMode]
  );

  const handleRubricMouseLeave = useCallback(
    (event) => {
      if (customizeMode && !rubricEditMode) {
        rubricHoverTimeoutRef.current = setTimeout(() => {
          if (!toolbarRef.current?.contains(event.relatedTarget)) {
            setShowRubricToolbar(false);
            setSelectedRubric(null);
          }
        }, 100);
      }
    },
    [customizeMode, rubricEditMode]
  );

  const handleToolbarMouseEnter = () => {
    if (rubricHoverTimeoutRef.current) {
      clearTimeout(rubricHoverTimeoutRef.current);
    }
  };

  const handleToolbarMouseLeave = (event) => {
    if (!selectedRubric?.contains(event.relatedTarget)) {
      setShowRubricToolbar(false);
      setSelectedRubric(null);
    }
  };

  const handleRubricAction = async (action) => {
    if (!selectedRubric) return;

    try {
      setLoading(true);
      let updatedContent = "";

      switch (action) {
        case "edit":
          setRubricEditMode(true);
          setSelectedRubricContent(selectedRubric.outerHTML);
          setRubricsCustomizationOpen(true);
          break;

        case "delete":
          setConfirmDialogProps({
            title: "Confirm Deletion",
            content: "Are you sure you want to delete this rubric?",
            onConfirm: () => {
              const newContent = assessmentContent.replace(
                selectedRubric.outerHTML,
                ""
              );
              setAssessmentContent(newContent);
              setSnackbarMessage("Rubric deleted successfully");
              setSnackbarSeverity("success");
              setSnackbarOpen(true);
              setConfirmDialogOpen(false);
            },
          });
          setConfirmDialogOpen(true);
          break;

        case "add":
          const newRubric = prompt("Enter new rubric content:");
          if (newRubric) {
            updatedContent = assessmentContent.replace(
              selectedRubric.outerHTML,
              selectedRubric.outerHTML + newRubric
            );
            setAssessmentContent(updatedContent);
            setSnackbarMessage("New rubric added successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
          break;

        case "save":
          // This case might be used for inline editing of the rubric
          // For now, we'll just show a success message
          setSnackbarMessage("Rubric saved successfully");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          break;

        default:
          console.warn(`Unhandled rubric action: ${action}`);
          return;
      }

      if (updatedContent) {
        setAssessmentContent(updatedContent);
      }
    } catch (error) {
      console.error(`Error ${action}ing rubric:`, error);
      setSnackbarMessage(`Failed to ${action} rubric. Please try again.`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRubricsCustomizationSubmit = async (customizationData) => {
    try {
      setLoading(true);

      const assessContent = document.querySelector(".assess-content").innerHTML;
      const rubricTable = document.querySelector(".rubrics_table");
      const rubricContent = rubricTable ? rubricTable.outerHTML : "";

      const payload = {
        assessmentId: id,
        assessmentContent: assessContent,
        rubricContent: rubricContent,
        customizationData: customizationData,
      };

      console.log("Sending payload:", payload);
      setLoadingMessage(`Thinking rubrics design...`);

      const response = await fetch(
        `${config.webUrl}/assessments/rubrics_customize`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to customize rubric");
      }

      const result = await response.json();
      // console.log("Received result:", result);

      if (!result.customizedRubrics) {
        throw new Error("No customized rubrics received from the server");
      }

      // Render the markdown content and sanitize the HTML
      setLoadingMessage(`Processing rubrics...`);
      const renderedRubric = DOMPurify.sanitize(
        marked(result.customizedRubrics)
      );

      // Update the rubric content
      if (rubricTable) {
        rubricTable.outerHTML = `<div class="rubrics_table">${renderedRubric}</div>`;
      } else {
        // If there's no existing rubric table, append the new one to the assessment content
        const assessContentElement = document.querySelector(".assess-content");
        if (assessContentElement) {
          const tempDiv = document.createElement("div");
          tempDiv.className = "rubrics_table";
          tempDiv.innerHTML = renderedRubric;
          assessContentElement.appendChild(tempDiv);
        }
      }

      // Update the state to reflect the changes
      setAssessmentContent(document.querySelector(".assess-content").innerHTML);

      setSnackbarMessage("Rubric customized successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error customizing rubric:", error);
      setSnackbarMessage(`Failed to customize rubric: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setRubricsCustomizationOpen(false);
      setRubricEditMode(false);
    }
  };

  useEffect(() => {
    const rubricDivs = document.querySelectorAll(".rubrics_table");
    rubricDivs.forEach((div) => {
      div.addEventListener("mouseenter", handleRubricMouseEnter);
      div.addEventListener("mouseleave", handleRubricMouseLeave);
    });

    return () => {
      rubricDivs.forEach((div) => {
        div.removeEventListener("mouseenter", handleRubricMouseEnter);
        div.removeEventListener("mouseleave", handleRubricMouseLeave);
      });
    };
  }, [assessmentContent, handleRubricMouseEnter, handleRubricMouseLeave]);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "95%", margin: "0 auto" }}
    >
      <Stack spacing={2} direction="row">
        <Button
          variant="outlined"
          onClick={() => navigate("/assessment/listall")}
        >
          Back to List
        </Button>
        <Button variant="contained" onClick={generateAlternative}>
          Generate Alternative
        </Button>
        <Button
          variant="contained"
          onClick={toggleCustomizeMode}
          sx={{
            backgroundColor: customizeMode ? "green" : "primary.main",
            "&:hover": {
              backgroundColor: customizeMode ? "darkgreen" : "primary.dark",
            },
          }}
        >
          {customizeMode ? "Exit Customize Mode" : "Customize"}
        </Button>
        <Tooltip title="Converse with the assessment">
          <IconButton onClick={toggleChat}>
            <ChatIcon />
          </IconButton>
        </Tooltip>
        {/* <Button
          variant="contained"
          onClick={toggleChat}
          startIcon={<ChatIcon />}
        ></Button> */}
      </Stack>
      <div className="space2"></div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Assessment" />
        {alternativeResults && <Tab label="Alternative Assessment" />}
      </Tabs>
      {activeTab === 0 && (
        <div
          className="assess-content"
          onMouseUp={handleTextSelect}
          // dangerouslySetInnerHTML={{
          //   __html: DOMPurify.sanitize(marked(assessmentContent)),
          // }}
        >
          <MarkdownRenderer content={assessmentContent} />
        </div>
      )}
      {activeTab === 1 && (
        <div className="assess-right-col">
          <Box
            mt={2}
            // dangerouslySetInnerHTML={{ __html: marked(alternativeResults) }}
          >
            <MarkdownRenderer content={alternativeResults} />
          </Box>
        </div>
      )}
      {showRubricToolbar && (
        <Paper
          ref={toolbarRef}
          onMouseEnter={handleToolbarMouseEnter}
          onMouseLeave={handleToolbarMouseLeave}
          style={{
            position: "absolute",
            top: rubricToolbarPosition.top,
            left: rubricToolbarPosition.left,
            transform: "translate(-50%, -100%)",
            opacity: 0.95,
            padding: "8px",
            zIndex: 1000,
          }}
        >
          <Typography variant="subtitle2" align="center">
            Customize Rubric
          </Typography>
          <Box display="flex" justifyContent="center">
            <Tooltip title="Edit">
              <IconButton onClick={() => handleRubricAction("edit")}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => handleRubricAction("delete")}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Add">
              <IconButton onClick={() => handleRubricAction("add")}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Save">
              <IconButton onClick={() => handleRubricAction("save")}>
                <SaveIcon />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Paper>
      )}

      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        {...confirmDialogProps}
      />
      {showPrintButton && (
        <Box mt={2}>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={handlePrint}
            sx={{ mr: 2 }}
          >
            Print
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadClick}
            startIcon={<DownloadIcon />}
            sx={{ mr: 2 }}
          >
            Download As
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAsNew}
          >
            Save as New Assessment
          </Button>

          <Menu
            anchorEl={downloadAnchorEl}
            open={Boolean(downloadAnchorEl)}
            onClose={handleDownloadClose}
          >
            <MenuItem onClick={() => handleDownload('pdf')}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText>Download as PDF</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleDownload('docx')}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText>Download as Word</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      )}

      {assessment && (
        <ChatDrawer
          open={chatOpen}
          onClose={toggleChat}
          assessmentId={id}
          assessmentContent={assessmentContent}
        />
      )}
      <TextModal
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        selectedText={selectedText}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <RubricsCustomizationModal
        open={rubricsCustomizationOpen}
        onClose={() => {
          setRubricsCustomizationOpen(false);
          setRubricEditMode(false);
        }}
        onSubmit={handleRubricsCustomizationSubmit}
      />
      {loading && <FlexLoader rubricType={loadingMessage} />}
    </Box>
  );
}

export default AssessmentDetail;
