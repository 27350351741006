import React from 'react';
import { 
    Grid, 
    Card, 
    CardContent, 
    Typography, 
    Avatar, 
    Box, 
    Link,
    Container,
    Paper,
    Divider
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';

const teamMembers = [
    {
        name: 'Fazmin Nizam',
        title: 'Lead AI Engineer and Developer',
        role: 'Technical Lead',
        description: 'Leads the development of AI systems and platform architecture.',
        expertise: 'AI/ML, Software Engineering, Educational Technology',
        contact: 'fazmin@mcmaster.ca',
        image: '/api/placeholder/150/150'
    },
    {
        name: 'Stephanie Verkoeyen, PhD',
        title: 'Educational Developer',
        role: 'Pedagogical Lead',
        description: 'Guides educational design and assessment methodology.',
        expertise: 'Educational Development, Assessment Design, Pedagogy',
        contact: 'verkoeys@mcmaster.ca',
        image: '/api/placeholder/150/150'
    },
    {
        name: 'Erin Aspenlieder, PhD',
        title: 'Advisor',
        role: 'Strategic Advisor',
        description: 'Provides strategic direction and educational expertise.',
        expertise: 'Higher Education, Educational Leadership, Assessment Strategy',
        contact: 'erin.aspenlieder@sheridancollege.ca',
        image: '/api/placeholder/150/150'
    }
];

const Team = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ py: 6 }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Our Team
                </Typography>

                <Paper elevation={2} sx={{ p: 4, mt: 4 }} className='papercard'>
                    <Box sx={{ mb: 4 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <GroupsIcon sx={{ fontSize: 30, mr: 2, color: 'primary.main' }} />
                            <Typography variant="h5" gutterBottom>
                                Meet the Experts Behind Assessment Partner
                            </Typography>
                        </Box>
                        <Typography variant="body1" paragraph>
                            Our team brings together expertise in artificial intelligence, educational development, and assessment design. We are committed to transforming assessment creation in higher education through innovative technology and pedagogical excellence.
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <Grid container spacing={4} justifyContent="center">
                        {teamMembers.map((member, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                <Card sx={{ 
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'translateY(-8px)',
                                        boxShadow: (theme) => theme.shadows[8]
                                    }
                                }}>
                                    <Box sx={{ 
                                        p: 3, 
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        bgcolor: 'primary.main',
                                        color: 'primary.contrastText'
                                    }}>
                                        <Avatar
                                            src={member.image}
                                            sx={{ 
                                                width: 120, 
                                                height: 120,
                                                border: '4px solid',
                                                borderColor: 'background.paper'
                                            }}
                                        />
                                        <Typography variant="h6" align="center" sx={{ mt: 2, fontWeight: 600 }}>
                                            {member.name}
                                        </Typography>
                                        <Typography variant="subtitle1" align="center">
                                            {member.title}
                                        </Typography>
                                    </Box>

                                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                                        <Box sx={{ mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <WorkIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle2" color="primary">
                                                    Role
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" paragraph>
                                                {member.role}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ mb: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                <SchoolIcon sx={{ mr: 1, color: 'primary.main' }} />
                                                <Typography variant="subtitle2" color="primary">
                                                    Expertise
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" paragraph>
                                                {member.expertise}
                                            </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 'auto' }}>
                                            <EmailIcon sx={{ mr: 1, color: 'primary.main' }} />
                                            <Link 
                                                href={`mailto:${member.contact}`} 
                                                color="primary"
                                                sx={{ 
                                                    textDecoration: 'none',
                                                    '&:hover': { textDecoration: 'underline' }
                                                }}
                                            >
                                                {member.contact}
                                            </Link>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default Team;
