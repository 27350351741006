import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  Tabs,
  Tab,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Tooltip,
  Zoom,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import DOMPurify from "dompurify";
import LayersIcon from "@mui/icons-material/Layers";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useAuth } from "./useAuth";
import "../App.css";
import config from "../config";
import { marked } from "marked";
import axios from "../axiosConfig";

function AssessmentList() {
  const [assessments, setAssessments] = useState([]);
  const [mcqs, setMcqs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const assessmentsResponse = await fetch(
          `${config.webUrl}/assessments/`
        );
        const assessmentsData = await assessmentsResponse.json();
        setAssessments(assessmentsData);

        const mcqsResponse = await fetch(`${config.webUrl}/mcq-shared-list/`);
        const mcqsData = await mcqsResponse.json();
        setMcqs(mcqsData);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setSnackbar({
          open: true,
          message: "Error fetching data",
          severity: "error",
        });
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleDeleteClick = (item, type) => {
    setItemToDelete({ ...item, type });
    setDeleteDialogOpen(true);
  };

  const handleShareClick = (event, item) => {
    setShareAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
    setSelectedItem(null);
  };

  const handleShare = (platform) => {
    const url =
      window.location.origin +
      (selectedItem.type === "assessment"
        ? `/assessments/list/${selectedItem.id}`
        : `/mcqshared/${selectedItem.id}`);

    const title =
      selectedItem.type === "assessment"
        ? "Check out this assessment"
        : `Check out this MCQ set: ${selectedItem.title}`;

    let shareUrl = "";
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(title)}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          url
        )}`;
        break;
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${encodeURIComponent(
          title + " " + url
        )}`;
        break;
      case "copy":
        navigator.clipboard.writeText(url);
        setSnackbar({
          open: true,
          message: "Link copied to clipboard!",
          severity: "success",
        });
        handleShareClose();
        return;
      default:
        return;
    }
    window.open(shareUrl, "_blank");
    handleShareClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }

      const endpoint =
        itemToDelete.type === "assessment"
          ? `${config.webUrl}/assessments/${itemToDelete.id}`
          : `${config.webUrl}/mcq/${itemToDelete.id}`;

      const response = await axios({
        method: "DELETE",
        url: endpoint,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        if (itemToDelete.type === "assessment") {
          setAssessments(assessments.filter((a) => a.id !== itemToDelete.id));
        } else {
          setMcqs(mcqs.filter((m) => m.id !== itemToDelete.id));
        }

        setSnackbar({
          open: true,
          message: `${
            itemToDelete.type === "assessment" ? "Assessment" : "MCQ"
          } deleted successfully`,
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      setSnackbar({
        open: true,
        message:
          error.response?.data?.detail ||
          "Failed to delete item. Please check your permissions.",
        severity: "error",
      });
    } finally {
      setDeleteDialogOpen(false);
      setItemToDelete(null);
    }
  };

  const renderAssessmentCards = (items, type) => (
    <Grid container spacing={3} sx={{ mt: 2, px: 2 }}>
      {Array.isArray(items) &&
        items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                  },
                  position: "relative",
                  borderRadius: "12px",
                  overflow: "hidden",
                  bgcolor: "background.paper",
                }}
              >
                <CardContent
                  onClick={() =>
                    navigate(
                      type === "assessment"
                        ? `/assessments/list/${item.id}`
                        : `/mcqshared/${item.id}`
                    )
                  }
                  sx={{
                    cursor: "pointer",
                    flexGrow: 1,
                    p: 3,
                    "&:last-child": { pb: 3 },
                  }}
                >
                  {type === "assessment" ? (
                    <div
                      className="assessment-content"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          marked(item.output.substring(0, 200) + "...")
                        ),
                      }}
                    />
                  ) : (
                    <div>
                      <Typography variant="h6" gutterBottom color="primary">
                        {item.title || "MCQ Set"}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {item.no_of_questions} Questions
                      </Typography>
                      {item.questions.slice(0, 2).map((q, i) => (
                        <Typography
                          key={i}
                          variant="body2"
                          sx={{
                            mb: 1,
                            color: "text.primary",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {i + 1}. {q.question}
                        </Typography>
                      ))}
                    </div>
                  )}
                </CardContent>
                <CardActions sx={{ p: 2, pt: 0, justifyContent: "flex-end" }}>
                  <Tooltip title="Share">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShareClick(e, { ...item, type });
                      }}
                      size="small"
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  {user?.group === "admin" && (
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(item, type);
                        }}
                        size="small"
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </CardActions>
              </Card>
            </Zoom>
          </Grid>
        ))}
    </Grid>
  );

  return (
    <Box sx={{ maxWidth: "1200px", margin: "0 auto", p: 3 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "primary.main",
          textAlign: "center",
          mb: 4,
        }}
      >
        Shared Assessments and MCQs
      </Typography>

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{
          mb: 3,
          "& .MuiTab-root": {
            minWidth: 120,
            fontWeight: "medium",
          },
          "& .Mui-selected": {
            color: "primary.main",
          },
          "& .MuiTabs-indicator": {
            height: 3,
            borderRadius: "3px 3px 0 0",
          },
        }}
      >
        <Tab label="Assessments" icon={<LayersIcon />} iconPosition="start" />
        <Tab label="MCQs" icon={<LayersIcon />} iconPosition="start" />
      </Tabs>

      <Fade in={true}>
        <Box>
          {activeTab === 0 && renderAssessmentCards(assessments, "assessment")}
          {activeTab === 1 && renderAssessmentCards(mcqs, "mcq")}
        </Box>
      </Fade>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            p: 1,
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {itemToDelete?.type}? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleShareClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleShare("facebook")}>
          <FacebookIcon sx={{ mr: 1 }} /> Facebook
        </MenuItem>
        <MenuItem onClick={() => handleShare("twitter")}>
          <TwitterIcon sx={{ mr: 1 }} /> Twitter
        </MenuItem>
        <MenuItem onClick={() => handleShare("linkedin")}>
          <LinkedInIcon sx={{ mr: 1 }} /> LinkedIn
        </MenuItem>
        <MenuItem onClick={() => handleShare("whatsapp")}>
          <WhatsAppIcon sx={{ mr: 1 }} /> WhatsApp
        </MenuItem>
        <MenuItem onClick={() => handleShare("copy")}>
          <ContentCopyIcon sx={{ mr: 1 }} /> Copy Link
        </MenuItem>
      </Menu>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AssessmentList;
