import React, { useState, useEffect, useCallback } from "react";
import ReactFlow, {
  Background,
  Controls,
  useNodesState,
  useEdgesState,
} from "reactflow";
import { useNavigate } from "react-router-dom";
import { Box, Snackbar, Alert, Paper, Typography, Button } from "@mui/material";
import config from "../config";
import MarkdownRenderer from "./MarkdownRenderer";
import QueueStatus from "./QueueStatus";
import ConfirmationModal from "./ConfirmationModal";
import { LoadingIndicator } from "./CustomHooks";
import {
  AssessmentComponentsNode,
  StudyLevelNode,
  DisciplineNode,
  GenerateNode,
} from "./flow/CustomNodes";
import "./flow/FlowStyles.css";
import "reactflow/dist/style.css";

// Node types registration
const nodeTypes = {
  assessmentComponents: AssessmentComponentsNode,
  studyLevel: StudyLevelNode,
  discipline: DisciplineNode,
  generate: GenerateNode,
};

function SimpleFormFlow() {
  const navigate = useNavigate();
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Form Data State
  const [formData, setFormData] = useState({
    userLevels: [],
    mainFields: [],
    assignmentGroups: { Components: [] },
  });

  // Selected Values State
  const [selectedValues, setSelectedValues] = useState({
    assignments: [],
    userLevel: "",
    mainField: "",
    TopicField: "",
  });

  // UI State
  const [uiState, setUiState] = useState({
    messages: [],
    loading: false,
    resultContents: "",
    visibleContentLength: 0,
    showSaveButton: false,
    saveButtonDisabled: false,
    token: null,
    showPrintButton: false,
    showCustomizeButton: false,
    error: null,
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
    showConfirmationModal: false,
    queuePosition: 0,
    isQueued: false,
  });

  const handleValuesChange = useCallback((field, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }

      if (!ws || !isConnected) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "No connection available. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        return;
      }

      try {
        const formDataToSend = {
          assignmentTypes: selectedValues.assignments.map(
            (assignment) => assignment.optionValue
          ),
          userLevel: selectedValues.userLevel,
          mainField: selectedValues.mainField,
          TopicField: selectedValues.TopicField,
          TypeOfAssessments: "none",
          skillField: "none",
          genAiUse: "none",
          gptVersion: "gpt-4-1106-preview",
          formType: "simple",
        };

        ws.send(JSON.stringify(formDataToSend));
        setUiState((prev) => ({ ...prev, loading: true }));
      } catch (error) {
        console.error("Error sending data:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Failed to send request. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    },
    [ws, isConnected, selectedValues]
  );

  const handleGenerateClick = useCallback(
    (event) => {
      if (event) {
        event.preventDefault();
      }

      if (uiState.resultContents) {
        setUiState((prev) => ({
          ...prev,
          showConfirmationModal: true,
        }));
      } else {
        handleSubmit();
      }
    },
    [uiState.resultContents, handleSubmit]
  );

  // Initial nodes configuration
  const getInitialNodes = useCallback(
    () => [
      {
        id: "components",
        type: "assessmentComponents",
        position: { x: 250, y: 0 },
        data: {
          label: "Assessment Components",
          assignmentGroups: formData.assignmentGroups || { Components: [] },
          selectedValues: selectedValues,
          onValuesChange: handleValuesChange,
          loading: uiState.loading,
        },
      },
      {
        id: "level",
        type: "studyLevel",
        position: { x: 250, y: 150 },
        data: {
          label: "Study Level",
          userLevels: formData.userLevels || [],
          selectedValues: selectedValues,
          onValuesChange: handleValuesChange,
          loading: uiState.loading,
        },
      },
      {
        id: "discipline",
        type: "discipline",
        position: { x: 250, y: 300 },
        data: {
          label: "Discipline & Topic",
          mainFields: formData.mainFields || [],
          selectedValues: selectedValues,
          onValuesChange: handleValuesChange,
          loading: uiState.loading,
        },
      },
      {
        id: "generate",
        type: "generate",
        position: { x: 250, y: 450 },
        data: {
          label: "Generate",
          onGenerate: handleGenerateClick,
          loading: uiState.loading,
        },
      },
    ],
    [
      formData,
      selectedValues,
      uiState.loading,
      handleValuesChange,
      handleGenerateClick,
    ]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(getInitialNodes());

  // Initial edges configuration
  const initialEdges = [
    { id: "e1-2", source: "components", target: "level" },
    { id: "e2-3", source: "level", target: "discipline" },
    { id: "e3-4", source: "discipline", target: "generate" },
  ];

  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // Update nodes when form data changes
  useEffect(() => {
    setNodes(getInitialNodes());
  }, [formData, selectedValues, uiState.loading, getInitialNodes, setNodes]);

  // WebSocket message handler
  const handleWebSocketMessage = useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setUiState((prev) => ({
          ...prev,
          messages: [data.message],
          queuePosition: data.queuePosition,
          loading: true,
          isQueued: true,
        }));
        break;

      case "processing":
        const statusMessage = data.message || "Processing...";
        setUiState((prev) => ({
          ...prev,
          messages: [statusMessage],
          loading: true,
          isQueued: false,
        }));
        break;

      case "Success":
        setUiState((prev) => ({
          ...prev,
          resultContents: data.content,
          loading: false,
          token: data.token,
          messages: [],
        }));
        break;

      case "done":
        setUiState((prev) => ({
          ...prev,
          showSaveButton: true,
          showPrintButton: true,
          showCustomizeButton: true,
          loading: false,
          messages: [],
        }));
        break;

      default:
        if (data.error) {
          setUiState((prev) => ({
            ...prev,
            snackbarMessage: "An error occurred",
            snackbarSeverity: "error",
            snackbarOpen: true,
            loading: false,
            messages: [],
          }));
        }
    }
  }, []);

  // Initialize WebSocket
  useEffect(() => {
    const initWebSocket = () => {
      const wsInstance = new WebSocket(`${config.appUrl}/ws`);

      wsInstance.onopen = () => {
        setIsConnected(true);
        setUiState((prev) => ({
          ...prev,
          error: null,
          snackbarOpen: false,
        }));
      };

      wsInstance.onmessage = handleWebSocketMessage;

      wsInstance.onclose = (event) => {
        setIsConnected(false);
        if (!event.wasClean) {
          setUiState((prev) => ({
            ...prev,
            loading: false,
            snackbarMessage: "Connection lost. Please try again.",
            snackbarSeverity: "error",
            snackbarOpen: true,
          }));
        }
        if (!window.isUnloading) {
          setTimeout(initWebSocket, 3000);
        }
      };

      wsInstance.onerror = (error) => {
        console.error("WebSocket error:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Connection error. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      };

      setWs(wsInstance);
    };

    initWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [handleWebSocketMessage]);

  // Fetch initial form data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/api/data`);
        const data = await response.json();
        setFormData({
          userLevels: data.user_level || [],
          mainFields: data.main_field || [],
          assignmentGroups: {
            Components: data.assignments?.Componets || [],
          },
        });
      } catch (error) {
        setUiState((prev) => ({
          ...prev,
          error: "Failed to load form data",
          snackbarOpen: true,
          snackbarMessage: "Failed to load form data",
          snackbarSeverity: "error",
        }));
      }
    };
    fetchData();
  }, []);

  const handleConfirmGenerate = useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showConfirmationModal: false,
      loading: false,
      messages: [],
      resultContents: "",
      visibleContentLength: 0,
      showSaveButton: false,
      showPrintButton: false,
      showCustomizeButton: false,
      token: null,
    }));

    handleSubmit();
  }, [handleSubmit]);

  const handleSaveAndShare = async () => {
    try {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: true }));
      const response = await fetch(`${config.webUrl}/assessments/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: uiState.token }),
      });

      if (!response.ok) throw new Error("Failed to save assessment");

      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Assessment saved successfully",
        snackbarSeverity: "success",
        snackbarOpen: true,
      }));
    } catch (error) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to save assessment",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    } finally {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: false }));
    }
  };

  const handleCustomize = async () => {
    if (uiState.token) {
      try {
        await handleSaveAndShare();
        navigate(`/assessments/list/${uiState.token}`);
      } catch (error) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and customize",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    }
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Assessment Print</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            pre { white-space: pre-wrap; }
          </style>
        </head>
        <body>
          ${MarkdownRenderer({ content: uiState.resultContents })}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Box
      sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Paper elevation={0} sx={{ p: 2, bgcolor: "background.default" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Interactive Assessment Generator
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Create your assessment by connecting and configuring the nodes below.
        </Typography>
      </Paper>

      <Box className="flow-container">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          fitView
        >
          <Background />
          <Controls />
        </ReactFlow>
      </Box>

      {uiState.isQueued && (
        <QueueStatus
          queuePosition={uiState.queuePosition}
          message={uiState.messages[0]}
        />
      )}

      {uiState.loading && !uiState.isQueued && (
        <Box sx={{ mt: 2 }}>
          <LoadingIndicator messages={uiState.messages} />
        </Box>
      )}

      {uiState.resultContents && (
        <Paper className="flow-results">
          <MarkdownRenderer content={uiState.resultContents} />

          {uiState.showSaveButton && (
            <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
                disabled={uiState.saveButtonDisabled}
              >
                Save and Share
              </Button>

              {uiState.showCustomizeButton && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCustomize}
                  disabled={!uiState.token}
                >
                  Customize
                </Button>
              )}

              {uiState.showPrintButton && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              )}
            </Box>
          )}
        </Paper>
      )}

      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
          severity={uiState.snackbarSeverity}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>

      <ConfirmationModal
        open={uiState.showConfirmationModal}
        onConfirm={handleConfirmGenerate}
        onCancel={() =>
          setUiState((prev) => ({ ...prev, showConfirmationModal: false }))
        }
      />
    </Box>
  );
}

export default SimpleFormFlow;
