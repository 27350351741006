import React from 'react';
import SimpleForm from './SimpleForm';
import InfoSection from './InfoSection';
import { Box,} from "@mui/material";

function SimpleAssessmentGenerator() {
    return (
        <Box justifyContent="center" alignItems="center" sx={{ maxWidth: '80%', margin: '0 auto' }}>
            <h1 className="text-3xl font-bold my-4">Assessment Partner</h1>
            <h3 className="text-xl mb-4">Simple assessment development</h3>
            <SimpleForm />
            <InfoSection />
        </Box>
    );
}

export default SimpleAssessmentGenerator;
