import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingIndicator } from "./CustomHooks";
import "katex/dist/katex.min.css";
import config from "../config";
import MCQVerification from "./MCQVerification";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

function MCQMaster() {
  // Consolidated UI state
  const [uiState, setUiState] = useState({
    loading: false,
    messages: [],
    showSaveButton: false,
    showPrintButton: false,
    showVerification: false,
    verificationStatus: {},
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
    modelInfo: "",
    questions: [],
    fileId: null,
    mcqTitle: "",
    showAnswers: false,
  });

  // Form data state
  const [formData, setFormData] = useState({
    userLevels: [],
    mainFields: [],
    taxonomyModels: {},
  });

  // Selected values state
  const [selectedValues, setSelectedValues] = useState({
    numberOfQuestions: 5,
    difficulty: "medium",
    userLevel: "",
    mainField: "",
    topicField: "",
    skillField: "",
    taxonomyModel: "",
  });

  // WebSocket state
  const [ws, setWs] = useState(null);

  // WebSocket initialization
  useEffect(() => {
    const WS = new WebSocket(`${config.appUrl}/ws`);

    WS.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("Message from server:", data);

      switch (data.state) {
        case "processing":
          setUiState((prev) => ({
            ...prev,
            messages: [data.message],
          }));
          break;

        case "Success":
          setUiState((prev) => ({
            ...prev,
            fileId: data.file_id,
            loading: false,
          }));
          fetchMCQ(data.file_id);
          break;

        case "done":
          setUiState((prev) => ({
            ...prev,
            showSaveButton: true,
            showPrintButton: true,
          }));
          break;

        default:
          if (data.error) {
            console.error("Error:", data.error);
            setUiState((prev) => ({
              ...prev,
              snackbarMessage: "An error occurred",
              snackbarSeverity: "error",
              snackbarOpen: true,
              loading: false,
            }));
          }
      }
    };

    setWs(WS);

    return () => {
      if (WS && WS.readyState === WebSocket.OPEN) {
        WS.close();
      }
    };
  }, []);

  // Fetch initial data
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();
      setFormData({
        userLevels: data.user_level,
        mainFields: data.main_field,
        taxonomyModels: data.taxonomies,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to fetch form data",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Form submission handler
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      setUiState((prev) => ({
        ...prev,
        loading: true,
        messages: ["Generating multiple-choice questions..."],
      }));

      const formData = {
        ...selectedValues,
        formType: "mcq",
      };

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(formData));
      } else {
        console.error("WebSocket is not connected");
        setUiState((prev) => ({
          ...prev,
          messages: ["Error: WebSocket is not connected"],
          loading: false,
          snackbarMessage: "Connection error. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    },
    [selectedValues, ws]
  );

  // Fetch MCQ data
  const fetchMCQ = async (id) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}`);
      if (!response.ok) {
        throw new Error("Failed to fetch MCQ data");
      }
      const mcqData = await response.json();
      setUiState((prev) => ({
        ...prev,
        mcqTitle: mcqData.title,
        questions: mcqData.questions,
      }));
    } catch (error) {
      console.error("Error fetching MCQ:", error);
      setUiState((prev) => ({
        ...prev,
        messages: ["An error occurred while fetching the questions."],
        snackbarMessage: "Failed to fetch questions",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  };

  // Handle field changes
  const handleFieldChange = useCallback((field) => (event, newValue) => {
    setSelectedValues((prev) => ({
      ...prev,
      [field]: newValue?.optionValue ?? event.target.value,
    }));

    if (field === "numberOfQuestions") {
      const value = parseInt(event.target.value, 10);
      setUiState((prev) => ({
        ...prev,
        modelInfo:
          value > 10
            ? "Generating more than 10 questions may take longer due to the verification and overlap checking process."
            : "",
      }));
    }
  }, []);

  // Toggle answers visibility
  const toggleAnswers = useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showAnswers: !prev.showAnswers,
    }));
  }, []);

  // Validate MCQs
  const handleValidateMCQs = useCallback(async () => {
    for (let i = 0; i < uiState.questions.length; i++) {
      await verifyQuestion(i);
    }
  }, [uiState.questions]);

  // Verify individual question
  const verifyQuestion = async (index) => {
    setUiState((prev) => ({
      ...prev,
      verificationStatus: {
        ...prev.verificationStatus,
        [index]: { status: "verifying" },
      },
    }));

    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: uiState.questions[index].question,
          selected_answer:
            uiState.questions[index].options[
              uiState.questions[index].correctAnswer
            ],
        }),
      });
      const data = await response.json();

      setUiState((prev) => ({
        ...prev,
        verificationStatus: {
          ...prev.verificationStatus,
          [index]: {
            status: data.result,
            explanation: data.explanation,
          },
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setUiState((prev) => ({
        ...prev,
        verificationStatus: {
          ...prev.verificationStatus,
          [index]: {
            status: "re-verify",
            explanation: "An error occurred during verification. Please try again.",
          },
        },
      }));
    }
  };

  // Save and share handler
  const handleSaveAndShare = async () => {
    if (uiState.fileId) {
      try {
        const response = await fetch(
          `${config.webUrl}/mcq/save-and-share/${uiState.fileId}`,
          {
            method: "POST",
          }
        );
        if (!response.ok) {
          throw new Error("Failed to save and share MCQ");
        }
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "MCQ saved and shared successfully",
          snackbarSeverity: "success",
          snackbarOpen: true,
        }));
      } catch (error) {
        console.error("Error saving and sharing MCQ:", error);
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and share MCQ",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    }
  };

  // Print handler
  const handlePrint = useCallback(() => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print MCQ</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .question { margin-bottom: 20px; }
            .options { margin-left: 20px; }
            .reason { font-style: italic; color: #666; }
          </style>
        </head>
        <body>
          ${uiState.questions
            .map(
              (q, index) => `
              <div class="question">
                <h3>${index + 1}. ${q.question}</h3>
                <div class="options">
                  ${q.options
                    .map(
                      (option, i) => `
                      <p>${String.fromCharCode(65 + i)}. ${option}</p>
                    `
                    )
                    .join("")}
                </div>
                <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                  65 + q.correctAnswer
                )}</p>
                <p class="reason"><strong>Explanation:</strong> ${q.reason}</p>
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  }, [uiState.questions]);

  // Markdown renderer
  const renderMessage = useCallback((content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={{
          p: ({ node, ...props }) => <Typography paragraph {...props} />,
          h3: ({ node, ...props }) => (
            <Typography variant="h6" gutterBottom {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "10px" }} {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    );
  }, []);

  // Memoized loading component
  const LoadingComponent = useMemo(
    () => (
      <Box>
        {uiState.loading && <LoadingIndicator messages={uiState.messages} />}
      </Box>
    ),
    [uiState.loading, uiState.messages]
  );

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold my-4">
          Multiple Choice Question Generator
        </h1>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              {/* Main Field Selection */}
              <FormControl fullWidth margin="normal">
                <Tooltip
                  title="Select a Discipline. This field is searchable."
                  placement="right"
                >
                  <Autocomplete
                    options={formData.mainFields}
                    getOptionLabel={(option) => option.optionText}
                    value={
                      formData.mainFields.find(
                        (option) => option.optionValue === selectedValues.mainField
                      ) || null
                    }
                    onChange={(event, newValue) =>
                      handleFieldChange("mainField")(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select a Discipline" />
                    )}
                  />
                </Tooltip>
              </FormControl>

              {/* User Level Selection */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Level of study</InputLabel>
                <Select
                  value={selectedValues.userLevel}
                  onChange={(e) => handleFieldChange("userLevel")(e)}
                  label="Level of study"
                >
                  {formData.userLevels.map((item) => (
                    <MenuItem value={item.optionValue} key={item.optionValue}>
                      {item.optionText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Topic Field */}
              {selectedValues.mainField &&
                selectedValues.mainField !== "other" &&
                selectedValues.mainField !== "none" && (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      label="Topic within the discipline (optional)"
                      variant="outlined"
                      value={selectedValues.topicField}
                      onChange={(e) => handleFieldChange("topicField")(e)}
                      placeholder="e.g. Algebra, Geometry, etc."
                    />
                  </FormControl>
                )}

              {/* Skills Field */}
              {selectedValues.mainField &&
                selectedValues.mainField !== "other" &&
                selectedValues.mainField !== "none" && (
                  <FormControl fullWidth margin="normal">
                    <Tooltip
                      title="Enter specific skills, competencies, or specializations"
                      placement="right"
                    >
                      <TextField
                        label="Skills | Competencies | Specialization (optional)"
                        variant="outlined"
                        value={selectedValues.skillField}
                        onChange={(e) => handleFieldChange("skillField")(e)}
                        placeholder="e.g. Data Analysis, Critical Thinking, etc."
                      />
                    </Tooltip>
                  </FormControl>
                )}

              {/* Taxonomy Model Selection */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Learning Taxonomy or Model</InputLabel>
                <Tooltip
                  title="Educational taxonomy frameworks classify learning objectives"
                  placement="right"
                >
                  <Select
                    value={selectedValues.taxonomyModel}
                    onChange={(e) => handleFieldChange("taxonomyModel")(e)}
                    label="Learning Taxonomy or Model"
                  >
                    {Object.entries(formData.taxonomyModels).map(
                      ([group, items]) => [
                        <MenuItem
                          key={group}
                          value={group}
                          disabled
                          style={{ fontWeight: "bold" }}
                        >
                          {group}
                        </MenuItem>,
                        ...items.map((item) => (
                          <MenuItem
                            key={item.optionValue}
                            value={item.optionValue}
                            style={{ paddingLeft: "20px" }}
                          >
                            {item.optionText}
                          </MenuItem>
                        )),
                      ]
                    )}
                  </Select>
                </Tooltip>
              </FormControl>

              {/* Number of Questions Selection */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Number of Questions</InputLabel>
                <Select
                  value={selectedValues.numberOfQuestions}
                  onChange={(e) => handleFieldChange("numberOfQuestions")(e)}
                  label="Number of Questions"
                >
                  {[5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60].map((num) => (
                    <MenuItem key={num} value={num}>
                      {num}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Difficulty Selection */}
              <FormControl fullWidth margin="normal">
                <InputLabel>Level of difficulty</InputLabel>
                <Select
                  value={selectedValues.difficulty}
                  onChange={(e) => handleFieldChange("difficulty")(e)}
                  label="Level of difficulty"
                >
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </Select>
              </FormControl>

              {uiState.modelInfo && (
                <Alert severity="info" style={{ marginTop: "10px" }}>
                  {uiState.modelInfo}
                </Alert>
              )}

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={uiState.loading}
                >
                  Generate MCQs
                </Button>
              </Box>
            </form>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className="AssesmantGenMainInfo">
              <Typography variant="h5" component="h4">
                Generate targeted multiple-choice questions by specifying the
                subject, topic, learning objectives (using Bloom's Taxonomy or a
                similar framework), desired number of questions, difficulty level,
                and intended study level. The tool employs a systematic process to
                ensure question quality and alignment with learning outcomes,
                ensuring each MCQ contributes to a meaningful assessment.
                Additionally, we have implemented a verification process for each
                question and its corresponding answer to ensure accuracy.
              </Typography>
            </div>
            {LoadingComponent}
          </Grid>
        </Grid>

        {/* Generated Questions Section */}
        <Box mt={4}>
          {uiState.questions.length > 0 && (
            <div>
              <Typography variant="h6" gutterBottom>
                Generated MCQs:
              </Typography>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleValidateMCQs}
                  style={{ marginRight: "10px" }}
                >
                  Validate MCQs
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleAnswers}
                  startIcon={
                    uiState.showAnswers ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )
                  }
                >
                  {uiState.showAnswers ? "Hide Answers" : "Show Answers"}
                </Button>
              </Box>

              {/* Questions Display */}
              {uiState.questions.map((q, index) => (
                <Card key={index} sx={{ mb: 2, position: "relative" }}>
                  <CardContent className="singleCardContents">
                    <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                      <Tooltip
                        title={
                          <span style={{ fontSize: "0.85rem" }}>
                            {uiState.verificationStatus[index]?.explanation || ""}
                          </span>
                        }
                      >
                        <span>
                          <MCQVerification
                            status={uiState.verificationStatus[index]?.status}
                            onVerify={() => verifyQuestion(index)}
                          />
                        </span>
                      </Tooltip>
                    </Box>
                    <Typography variant="h6" gutterBottom>
                      Question {index + 1} of {uiState.questions.length}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {renderMessage(q.question)}
                    </Typography>
                    <RadioGroup className="q_radio_group">
                      {q.options.map((option, i) => (
                        <FormControlLabel
                          key={i}
                          value={String(i)}
                          control={<Radio />}
                          label={renderMessage(option)}
                          disabled
                        />
                      ))}
                    </RadioGroup>
                    {uiState.showAnswers && (
                      <>
                        <Typography
                          variant="body1"
                          className="mcq_answer"
                          color="primary"
                          sx={{ mt: 1 }}
                        >
                          Correct Answer:{" "}
                          {String.fromCharCode(65 + q.correctAnswer)}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Explanation: {renderMessage(q.reason)}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              ))}

              {/* Action Buttons */}
              <Box mt={2}>
                {uiState.showSaveButton && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveAndShare}
                    style={{ marginRight: "10px" }}
                  >
                    Save and Share
                  </Button>
                )}
                {uiState.showPrintButton && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                )}
              </Box>
            </div>
          )}
        </Box>

        {/* Snackbar for notifications */}
        <Snackbar
          open={uiState.snackbarOpen}
          autoHideDuration={6000}
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
        >
          <Alert
            onClose={() =>
              setUiState((prev) => ({ ...prev, snackbarOpen: false }))
            }
            severity={uiState.snackbarSeverity}
          >
            {uiState.snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    </Box>
  );
}

export default MCQMaster;