import axios from "axios";

const getBaseUrl = () => {
  const hostname = window.location.hostname;

  if (hostname === "localhost" || hostname === "127.0.0.1") {
    return "http://localhost:8000";
  }

  if (hostname === "172.26.210.220") {
    return "http://172.26.210.220:8000";
  }

  return "https://" + hostname;
};

const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor for debugging
instance.interceptors.request.use((request) => {
  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return request;
});

// Add a response interceptor for debugging
instance.interceptors.response.use(
  (response) => {
    // console.log('Response:', JSON.stringify(response, null, 2))
    return response;
  },
  (error) => {
    console.log("Response Error:", error.message);
    if (error.response) {
      console.log("Error Data:", error.response.data);
      console.log("Error Status:", error.response.status);
      console.log("Error Headers:", error.response.headers);
    } else if (error.request) {
      console.log("Error Request:", error.request);
    }
    return Promise.reject(error);
  }
);

export default instance;
