import React from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VerifiedIcon from '@mui/icons-material/Verified';

function MCQVerification({ status, onVerify }) {
    const getVerificationIcon = () => {
        switch (status) {
            case 'correct':
                return <CheckCircleOutlineIcon color="success" />;
            case 'incorrect':
                return <ErrorOutlineIcon color="error" />;
            case 're-verify':
                return <HelpOutlineIcon color="warning" />;
            case 'verifying':
                return <CircularProgress size={20} />;
            default:
                return (
                    <IconButton 
                        size="small" 
                        onClick={onVerify}
                        disabled={status === 'verifying'}
                    >
                        <VerifiedIcon />
                    </IconButton>
                );
        }
    };

    return getVerificationIcon();
}

export default MCQVerification;