import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
  IconButton,
  Toolbar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { withAuth, useAuth } from "./useAuth";

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    group: "basicuser",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const { getUsers, addUser, deleteUser, resetUserPassword, changeUserGroup } =
    useAuth();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Selection state
  const [selected, setSelected] = useState([]);

  // Delete confirmation dialog state
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    userId: null,
    multiple: false,
  });

  // Add these state variables at the beginning of the component
  const [orderBy, setOrderBy] = useState('email');
  const [order, setOrder] = useState('asc');

  // Add this state near the other state declarations
  const [bulkEmails, setBulkEmails] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const fetchedUsers = await getUsers();
      setUsers(fetchedUsers);
      setError(null);
    } catch (err) {
      setError("Failed to fetch users. Please try again.");
      console.error("Error fetching users:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddUser = async () => {
    try {
      await addUser(newUser);
      setNewUser({ email: "", password: "", group: "basicuser" });
      await fetchUsers();
      setSnackbar({
        open: true,
        message: "User added successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to add user: ${error.message}`,
        severity: "error",
      });
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      if (deleteDialog.multiple) {
        // Handle multiple deletions
        await Promise.all(selected.map((userId) => deleteUser(userId)));
        setSelected([]);
      } else {
        // Handle single deletion
        await deleteUser(deleteDialog.userId);
      }
      await fetchUsers();
      setSnackbar({
        open: true,
        message: "User(s) deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to delete user(s): ${error.message}`,
        severity: "error",
      });
    } finally {
      setDeleteDialog({ open: false, userId: null, multiple: false });
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      await resetUserPassword(userId);
      setSnackbar({
        open: true,
        message: "Password reset email sent",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to reset password: ${error.message}`,
        severity: "error",
      });
    }
  };

  const handleChangeUserGroup = async (userId, newGroup) => {
    try {
      await changeUserGroup(userId, newGroup);
      await fetchUsers();
      setSnackbar({
        open: true,
        message: "User group updated successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to update user group: ${error.message}`,
        severity: "error",
      });
    }
  };

  const handleBulkChangeUserGroup = async (newGroup) => {
    try {
      await Promise.all(
        selected.map((userId) => changeUserGroup(userId, newGroup))
      );
      await fetchUsers();
      setSelected([]);
      setSnackbar({
        open: true,
        message: "User groups updated successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to update user groups: ${error.message}`,
        severity: "error",
      });
    }
  };

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Selection handlers
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredUsers.map((user) => user.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSelectClick = (event, userId) => {
    const selectedIndex = selected.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (userId) => selected.indexOf(userId) !== -1;

  // Add this sorting function after the other handler functions
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Add this function to sort the data
  const sortData = (data) => {
    return data.sort((a, b) => {
      let valueA = a[orderBy];
      let valueB = b[orderBy];

      // Handle dates for created_at and last_login
      if (orderBy === 'created_at' || orderBy === 'last_login') {
        valueA = new Date(valueA || 0).getTime();
        valueB = new Date(valueB || 0).getTime();
      }

      if (valueB < valueA) {
        return order === 'desc' ? -1 : 1;
      }
      if (valueB > valueA) {
        return order === 'desc' ? 1 : -1;
      }
      return 0;
    });
  };

  // Update the filteredUsers calculation to include sorting
  const filteredUsers = sortData(
    users.filter((user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Get current page of users
  const currentUsers = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Add this function after the other handler functions
  const handleBulkInvite = async () => {
    try {
      const emails = bulkEmails.split(',').map(email => email.trim()).filter(email => email);
      if (emails.length === 0) {
        setSnackbar({
          open: true,
          message: "Please enter valid emails separated by commas",
          severity: "error"
        });
        return;
      }

      const response = await fetch('/api/bulk-invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ emails })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to send invites');
      }

      const data = await response.json();
      
      // Clear the input field
      setBulkEmails('');
      
      // Show results
      const successCount = data.results.filter(r => r.status === 'success').length;
      const failedCount = data.results.filter(r => r.status === 'failed').length;
      
      setSnackbar({
        open: true,
        message: `Invitations sent: ${successCount} successful, ${failedCount} failed`,
        severity: successCount > 0 ? "success" : "warning"
      });

      // If there are failures, log them to console for debugging
      const failures = data.results.filter(r => r.status === 'failed');
      if (failures.length > 0) {
        console.log('Failed invites:', failures);
      }

    } catch (error) {
      console.error('Bulk invite error:', error);
      setSnackbar({
        open: true,
        message: `Failed to send invites: ${error.message}`,
        severity: "error"
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 800, margin: "auto", mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "auto", marginLeft: 10, marginRight: 5, mt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Panel
      </Typography>

      {/* Add User Form */}
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Add User Email"
          value={newUser.email}
          onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          sx={{ mr: 1 }}
        />
        <TextField
          label="Password"
          type="password"
          value={newUser.password}
          onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
          sx={{ mr: 1 }}
        />
        <Select
          value={newUser.group}
          onChange={(e) => setNewUser({ ...newUser, group: e.target.value })}
          sx={{ mr: 1 }}
        >
          <MenuItem value="basicuser">Basic User</MenuItem>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="poweruser">Power User</MenuItem>
          <MenuItem value="admin">Admin</MenuItem>
        </Select>
        <Button onClick={handleAddUser} variant="contained">
          Add User
        </Button>
      </Box>

      {/* Search and Bulk Actions */}
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          label="Search Users"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "300px" }}
        />
        {selected.length > 0 && (
          <Box>
            <Select
              value=""
              displayEmpty
              onChange={(e) => handleBulkChangeUserGroup(e.target.value)}
              sx={{ mr: 1 }}
            >
              <MenuItem value="" disabled>
                Change Group
              </MenuItem>
              <MenuItem value="basicuser">Basic User</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="poweruser">Power User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteDialog({ open: true, multiple: true })}
            >
              Delete Selected
            </Button>
          </Box>
        )}
      </Box>

      {/* Users Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 &&
                    selected.length < filteredUsers.length
                  }
                  checked={
                    filteredUsers.length > 0 &&
                    selected.length === filteredUsers.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell 
                onClick={() => handleRequestSort('email')}
                style={{ cursor: 'pointer' }}
              >
                Email {orderBy === 'email' && (order === 'desc' ? '▼' : '▲')}
              </TableCell>
              <TableCell 
                onClick={() => handleRequestSort('created_at')}
                style={{ cursor: 'pointer' }}
              >
                Created At {orderBy === 'created_at' && (order === 'desc' ? '▼' : '▲')}
              </TableCell>
              <TableCell 
                onClick={() => handleRequestSort('last_login')}
                style={{ cursor: 'pointer' }}
              >
                Last Login {orderBy === 'last_login' && (order === 'desc' ? '▼' : '▲')}
              </TableCell>
              <TableCell 
                onClick={() => handleRequestSort('group')}
                style={{ cursor: 'pointer' }}
              >
                User Group {orderBy === 'group' && (order === 'desc' ? '▼' : '▲')}
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentUsers.map((user) => {
              const isItemSelected = isSelected(user.id);
              return (
                <TableRow key={user.id} selected={isItemSelected} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(event) => handleSelectClick(event, user.id)}
                    />
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {new Date(user.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {user.last_login
                      ? new Date(user.last_login).toLocaleString()
                      : "Never"}
                  </TableCell>
                  <TableCell>
                    <Select
                      value={user.group}
                      onChange={(e) =>
                        handleChangeUserGroup(user.id, e.target.value)
                      }
                    >
                      <MenuItem value="basicuser">Basic User</MenuItem>
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="poweruser">Power User</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() =>
                        setDeleteDialog({
                          open: true,
                          userId: user.id,
                          multiple: false,
                        })
                      }
                      color="error"
                      sx={{ mr: 1 }}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => handleResetPassword(user.id)}
                      color="primary"
                    >
                      Reset Password
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500, 1000]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialog.open}
        onClose={() =>
          setDeleteDialog({ open: false, userId: null, multiple: false })
        }
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {deleteDialog.multiple
              ? `Are you sure you want to delete ${selected.length} selected users?`
              : "Are you sure you want to delete this user?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeleteDialog({ open: false, userId: null, multiple: false })
            }
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Add this JSX after the Add User Form section */}
      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Bulk Invite Users
        </Typography>
        <TextField
          label="Enter email addresses (comma-separated)"
          value={bulkEmails}
          onChange={(e) => setBulkEmails(e.target.value)}
          multiline
          rows={2}
          fullWidth
          sx={{ mb: 1 }}
          placeholder="email1@example.com, email2@example.com, ..."
        />
        <Button 
          onClick={handleBulkInvite} 
          variant="contained" 
          color="primary"
        >
          Send Invitations
        </Button>
      </Box>
    </Box>
  );
};

export default withAuth(AdminPanel, "admin");
