import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Stack,
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
  IconButton,
} from "@mui/material";
import DOMPurify from "dompurify";
import { marked } from "marked";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import config from "../config";

function TextModal({ open, onClose, onSubmit, selectedText }) {
  const [inputText, setInputText] = useState("");

  const handleSubmit = useCallback(() => {
    onSubmit(inputText);
    onClose();
  }, [inputText, onSubmit, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Typography>{selectedText}</Typography>
        <TextField
          autoFocus
          margin="dense"
          label="How do you want to rewrite this?"
          fullWidth
          multiline
          rows={4}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <IconButton onClick={handleSubmit}>
          <TipsAndUpdatesIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

function CustomizeAssessment() {
  const { id } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [assessmentSections, setAssessmentSections] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedElementId, setSelectedElementId] = useState("");
  const [sectionLoading, setSectionLoading] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.webUrl}/assessments/${id}`);
        if (!response.ok) {
          throw new Error("Assessment not found");
        }
        const data = await response.json();
        setAssessment(data);
        const sections = parseContentIntoSections(data.output);
        setAssessmentSections(sections);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const parseContentIntoSections = (content) => {
    // Similar implementation as in `AssessmentDetail` to parse content into sections
    // ...
  };

  const openModal = useCallback((text, elementId) => {
    setSelectedText(text);
    setSelectedElementId(elementId);
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalSubmit = useCallback(async (inputText) => {
    try {
      setSectionLoading((prev) => ({ ...prev, [selectedElementId]: true }));
      const response = await fetch(`${config.webUrl}/assessments/customize`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          assessmentId: id,
          selectedText: selectedText,
          userInput: inputText,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to customize section");
      }
  
      const data = await response.json();
      const rewrittenText = data.rewrittenText;
  
      setAssessmentSections((prevSections) =>
        prevSections.map((section) =>
          section.id === selectedElementId
            ? { ...section, content: rewrittenText }
            : section
        )
      );
  
      setSectionLoading((prev) => ({ ...prev, [selectedElementId]: false }));
      closeModal();
    } catch (error) {
      console.error("Error customizing section:", error);
      setSectionLoading((prev) => ({ ...prev, [selectedElementId]: false }));
    }
  }, [id, selectedElementId, selectedText, closeModal]);

  if (!assessment) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant="h4">Customize Assessment</Typography>
      {assessmentSections.map((section) => (
        <div key={section.id} className="hoverable-section">
          {sectionLoading[section.id] && <CircularProgress size={26} />}
          <div
            dangerouslySetInnerHTML={{ __html: marked(DOMPurify.sanitize(section.content)) }}
            onMouseEnter={(e) => e.currentTarget.parentNode.classList.add("hovered")}
            onMouseLeave={(e) => e.currentTarget.parentNode.classList.remove("hovered")}
          />
          <IconButton
            onClick={() => openModal(section.content, section.id)}
          >
            <EditNoteIcon />
          </IconButton>
        </div>
      ))}
      <TextModal
        open={modalOpen}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
        selectedText={selectedText}
      />
    </Box>
  );
}

export default CustomizeAssessment;
