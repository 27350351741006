import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';

const CreateAccountPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const { createAccount } = useAuth();

  useEffect(() => {
    console.log('CreateAccountPage component mounted');
  }, []);

  useEffect(() => {
    let timer;
    if (showSuccessMessage) {
      timer = setTimeout(() => {
        navigate('/login');
      }, 10000); // 10 seconds
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showSuccessMessage, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    console.log('Email:', email);
    console.log('Password length:', password.length);

    if (password !== confirmPassword) {
      console.log("Passwords don't match");
      alert("Passwords don't match");
      return;
    }

    try {
      console.log('Attempting to create account');
      await createAccount(email, password);
      console.log('Account created successfully');
      setShowSuccessMessage(true);
    } catch (error) {
      console.error('Account creation failed:', error);
      alert(`Account creation failed: ${error.message}`);
    }
  };

  return (
    <Box sx={{ 
      maxWidth: '600px', // Increased from 300px
      margin: 'auto',
      mt: 4,
      padding: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Create Account
      </Typography>
      
      <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth 
          sx={{ mt: 2 }}
        >
          Create Account
        </Button>
      </Box>

      {showSuccessMessage && (
        <Box sx={{ 
          width: '100%', 
          mt: 4,
          p: 3,
          backgroundColor: '#31363b', // Light green background
          borderRadius: 2,
          boxShadow: 2,
          textAlign: 'center'
        }}>
          <Typography 
            variant="h5" 
            component="div" 
            sx={{ 
              mb: 2,
              color: '#b9d8f0', // Dark green text
              fontWeight: 'bold'
            }}
          >
            Account Created Successfully!
          </Typography>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              mb: 1,
              color: '#b9d8f0' // Darker green text
            }}
          >
            Thank you for signing up!
          </Typography>
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '1.1rem',
              mb: 1
            }}
          >
            You can now login by clicking{' '}
            <a 
              href="https://assessment-partner.com/login"
              style={{
                color: '#8eaec7',
                fontWeight: 'bold',
                textDecoration: 'none'
              }}
            >
              here
            </a>
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              mt: 2,
              color: '#5288b1',
              fontStyle: 'italic'
            }}
          >
            Redirecting to login page in 10 seconds...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CreateAccountPage;