const getConfig = () => {
  const host = window.location.hostname;
  const isProduction =
    host === "assessment-partner.com" || host === "www.assessment-partner.com";

  const isExternalTest =
    host === "172.26.210.220";

  if (isExternalTest) {
    return {
      webUrl: `http://172.26.210.220:8000`,
      appUrl: `ws://172.26.210.220:8000`,
      isDevelopment: false,
      isProduction: true,
    };
  }

  if (isProduction) {
    return {
      webUrl: `https://${host}`,
      appUrl: `wss://${host}`,
      isDevelopment: false,
      isProduction: true,
    };
  }

  // Development configuration
  return {
    webUrl: `http://127.0.0.1:8000`,
    appUrl: `ws://127.0.0.1:8000`,
    isDevelopment: true,
    isProduction: false,
  };
};

const config = getConfig();

// For debugging
if (process.env.NODE_ENV !== "production") {
  console.log("Current configuration:", config);
}

export default config;
