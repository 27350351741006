import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Paper,
} from "@mui/material";

function RubricsCustomizationModal({ open, onClose, onSubmit }) {
  const [rubricType, setRubricType] = useState("Analytic Rubrics");
  const [totalScore, setTotalScore] = useState(50);
  const [ratingLevels, setRatingLevels] = useState("Optimal");
  const [criteriaCount, setCriteriaCount] = useState("Optimal");
  const [prompt, setPrompt] = useState("");

  const rubricDescriptions = {
    "Analytic Rubrics":
      "Evaluates multiple criteria separately using different levels of performance. Ideal for complex assignments where detailed feedback on specific components is needed.",
    "Developmental Rubrics":
      "Tracks progress across developmental stages, focusing on growth and improvement over time. Perfect for long-term skill development and learning progression.",
    "Holistic Rubric":
      "Provides a single overall assessment of performance. Best for tasks where the whole is greater than the sum of its parts, such as creative writing or presentations.",
    "Single-Point Rubric":
      "Describes only proficient performance, with space for noting areas of concern and excellence. Great for promoting student self-reflection and targeted feedback.",
    "Scoring Rubric":
      "Emphasizes point allocation and standardized scoring. Ideal for standardized assessments or when consistent numerical scoring is required.",
    "Checklist Rubric":
      "Uses a simple yes/no format to verify if specific criteria are met. Perfect for tasks with clear, binary requirements or procedural assignments.",
  };

  const handleSubmit = () => {
    const customizationData = {
      rubricType,
      totalScore: ["Analytic Rubrics", "Scoring Rubric"].includes(rubricType)
        ? totalScore
        : undefined,
      ratingLevels: [
        "Analytic Rubrics",
        "Developmental Rubrics",
        "Holistic Rubric",
        "Scoring Rubric",
      ].includes(rubricType)
        ? ratingLevels
        : undefined,
      criteriaCount: [
        "Analytic Rubrics",
        "Developmental Rubrics",
        "Single-Point Rubric",
        "Scoring Rubric",
        "Checklist Rubric",
      ].includes(rubricType)
        ? criteriaCount
        : undefined,
      prompt,
    };
    onSubmit(customizationData);
    onClose();
  };

  useEffect(() => {
    setTotalScore(50);
    setRatingLevels("Optimal");
    setCriteriaCount("Optimal");
  }, [rubricType]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Rubrics Customization</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Rubric Type</InputLabel>
            <Select
              value={rubricType}
              onChange={(e) => setRubricType(e.target.value)}
              label="Rubric Type"
            >
              <MenuItem value="Analytic Rubrics">Analytic Rubrics</MenuItem>
              <MenuItem value="Developmental Rubrics">
                Developmental Rubrics
              </MenuItem>
              <MenuItem value="Holistic Rubric">Holistic Rubric</MenuItem>
              <MenuItem value="Single-Point Rubric">
                Single-Point Rubric
              </MenuItem>
              <MenuItem value="Scoring Rubric">Scoring Rubric</MenuItem>
              <MenuItem value="Checklist Rubric">Checklist Rubric</MenuItem>
            </Select>
          </FormControl>

          <Paper
            elevation={0}
            sx={{
              p: 2,
              bgcolor: "primary.light",
              color: "primary.contrastText",
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Typography variant="body2">
              {rubricDescriptions[rubricType]}
            </Typography>
          </Paper>

          {["Analytic Rubrics", "Scoring Rubric"].includes(rubricType) && (
            <FormControl fullWidth>
              <InputLabel>Total score for the rubric</InputLabel>
              <Select
                value={totalScore}
                onChange={(e) => setTotalScore(e.target.value)}
                label="Total score for the rubric"
              >
                {[
                  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
                  85, 90, 95, 100,
                ].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {[
            "Analytic Rubrics",
            "Developmental Rubrics",
            "Holistic Rubric",
            "Scoring Rubric",
          ].includes(rubricType) && (
            <FormControl fullWidth>
              <InputLabel>Number of rating levels</InputLabel>
              <Select
                value={ratingLevels}
                onChange={(e) => setRatingLevels(e.target.value)}
                label="Number of rating levels"
              >
                <MenuItem value="Optimal">Optimal</MenuItem>
                {[2, 3, 4, 5, 6, 7, 8].map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {[
            "Analytic Rubrics",
            "Developmental Rubrics",
            "Single-Point Rubric",
            "Scoring Rubric",
          ].includes(rubricType) && (
            <FormControl fullWidth>
              <InputLabel>Number of criteria</InputLabel>
              <Select
                value={criteriaCount}
                onChange={(e) => setCriteriaCount(e.target.value)}
                label="Number of criteria"
              >
                <MenuItem value="Optimal">Optimal</MenuItem>
                {[2, 3, 4, 5, 6, 7, 8].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {["Checklist Rubric"].includes(rubricType) && (
            <FormControl fullWidth>
              <InputLabel>Number of criteria</InputLabel>
              <Select
                value={criteriaCount}
                onChange={(e) => setCriteriaCount(e.target.value)}
                label="Number of criteria"
              >
                <MenuItem value="Optimal">Optimal</MenuItem>
                {[3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Special requests"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            helperText="Add any specific requirements or modifications you'd like for your rubric"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RubricsCustomizationModal;
