import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Box,
  Button,
  CircularProgress,
  Container,
  Snackbar,
  Tooltip,
  IconButton,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import config from "../config";
import MCQCustomizationModal from "./MCQCustomizationModal";
import MCQVerification from "./MCQVerification";

import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";

function MCQDetail() {
  const [mcqData, setMcqData] = useState({ title: "", questions: [] });
  const [loading, setLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [showAnswers, setShowAnswers] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [customizingQuestions, setCustomizingQuestions] = useState(new Set());
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMCQ = async () => {
      try {
        const response = await fetch(`${config.webUrl}/mcqshared/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch MCQ data");
        }
        const data = await response.json();

        // Ensure each question has an ID
        const questionsWithIds = data.questions.map((q, index) => ({
          ...q,
          id: q.id || String(index + 1), // Use existing ID or create one based on index
        }));

        setMcqData({
          ...data,
          questions: questionsWithIds,
        });
      } catch (error) {
        console.error("Error fetching MCQ:", error);
        setSnackbarMessage("Failed to load MCQ data");
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchMCQ();
  }, [id]);

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeKatex]}
        components={{
          p: ({ node, ...props }) => <Typography paragraph {...props} />,
          h3: ({ node, ...props }) => (
            <Typography variant="h6" gutterBottom {...props} />
          ),
          li: ({ node, ...props }) => (
            <li style={{ marginBottom: "10px" }} {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  const handleValidateMCQs = async () => {
    for (let i = 0; i < mcqData.questions.length; i++) {
      await verifyQuestion(i);
    }
  };

  const verifyQuestion = async (index) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [index]: { status: "verifying" },
    }));
    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: mcqData.questions[index].question,
          selected_answer:
            mcqData.questions[index].options[
              mcqData.questions[index].correctAnswer
            ],
        }),
      });
      const data = await response.json();
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: data.result,
          explanation: data.explanation,
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: "re-verify",
          explanation:
            "An error occurred during verification. Please try again.",
        },
      }));
    }
  };

  const handleCustomization = async (customizationData) => {
    try {
      setCustomizingQuestions((prev) => new Set(prev).add(selectedQuestionId));

      const response = await fetch(`${config.webUrl}/mcq/customize`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mcqId: id,
          questionId: selectedQuestionId,
          customizationData,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to customize question");
      }

      const data = await response.json();

      // Update only the specific question in the state
      setMcqData((prevData) => ({
        ...prevData,
        questions: prevData.questions.map((q) =>
          q.id === selectedQuestionId ? data.question : q
        ),
      }));

      setSnackbarMessage("Question customized successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error customizing question:", error);
      setSnackbarMessage(`Failed to customize question: ${error.message}`);
      setSnackbarOpen(true);
    } finally {
      setCustomizingQuestions((prev) => {
        const newSet = new Set(prev);
        newSet.delete(selectedQuestionId);
        return newSet;
      });
      setCustomizationModalOpen(false);
    }
  };

  const openCustomizationModal = (questionId) => {
    setSelectedQuestionId(questionId);
    setCustomizationModalOpen(true);
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print MCQ</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .question { margin-bottom: 20px; }
            .options { margin-left: 20px; }
            .reason { font-style: italic; color: #666; }
          </style>
        </head>
        <body>
          <h1>${mcqData.title || "Multiple Choice Questions"}</h1>
          ${mcqData.questions
            .map(
              (q, index) => `
              <div class="question">
                <h3>Question ${index + 1}</h3>
                <p>${q.question}</p>
                <div class="options">
                  ${q.options
                    .map(
                      (option, i) => `
                      <p>${String.fromCharCode(65 + i)}. ${option}</p>
                    `
                    )
                    .join("")}
                </div>
                <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                  65 + q.correctAnswer
                )}</p>
                <p class="reason"><strong>Explanation:</strong> ${q.reason}</p>
              </div>
            `
            )
            .join("")}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  if (loading) {
    return (
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Loading MCQ data...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        style={{ marginBottom: "20px" }}
      >
        Back to List
      </Button>

      <Typography variant="h4" gutterBottom>
        {mcqData.title || "Multiple Choice Questions"}
      </Typography>

      <Typography variant="subtitle1" gutterBottom>
        Total Questions: {mcqData.questions.length}
      </Typography>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" onClick={handlePrint}>
          Print
        </Button>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleValidateMCQs}
            style={{ marginRight: "10px" }}
          >
            Validate MCQs
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowAnswers(!showAnswers)}
            startIcon={showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />}
          >
            {showAnswers ? "Hide Answers" : "Show Answers"}
          </Button>
        </Box>
      </Box>

      <Box mt={4}>
        {mcqData.questions.map((q, index) => (
          <Card key={q.id} sx={{ mb: 2, position: "relative" }}>
            <CardContent className="singleCardContents">
              {customizingQuestions.has(q.id) && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    zIndex: 1,
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>
                      Customizing question...
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                <Tooltip
                  title={
                    <span style={{ fontSize: "0.85rem" }}>
                      {verificationStatus[index]?.explanation || ""}
                    </span>
                  }
                >
                  <span>
                    <MCQVerification
                      status={verificationStatus[index]?.status}
                      onVerify={() => verifyQuestion(index)}
                    />
                  </span>
                </Tooltip>
                <Tooltip title="Customize Question">
                  <IconButton
                    onClick={() => openCustomizationModal(q.id)}
                    sx={{ ml: 1 }}
                    disabled={customizingQuestions.has(q.id)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              <Typography variant="h6" gutterBottom>
                Question {index + 1} of {mcqData.questions.length}
              </Typography>

              <Typography variant="body1" gutterBottom>
                {renderMessage(q.question)}
              </Typography>

              <RadioGroup className="q_radio_group">
                {q.options.map((option, i) => (
                  <FormControlLabel
                    key={i}
                    value={String(i)}
                    control={<Radio />}
                    label={renderMessage(option)}
                  />
                ))}
              </RadioGroup>

              {showAnswers && (
                <>
                  <Typography
                    variant="body1"
                    className="mcq_answer"
                    color="primary"
                    sx={{ mt: 1 }}
                  >
                    Correct Answer: {String.fromCharCode(65 + q.correctAnswer)}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Explanation: {renderMessage(q.reason)}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>

      <MCQCustomizationModal
        open={customizationModalOpen}
        onClose={() => setCustomizationModalOpen(false)}
        onSubmit={handleCustomization}
        questionId={selectedQuestionId}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
}

export default MCQDetail;
