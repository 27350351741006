import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Container,
  Box,
  Paper,
  Chip,
  Tooltip,
  useTheme
} from "@mui/material";
import { useAuth } from './useAuth';

export default function ActionAreaCard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, hasPermission } = useAuth();

  const cards = [
    {
      id: "SIMPLE_ASSESSMENT",
      title: "Simple Assessment Generation",
      description: "Unsure of where to begin? With just few clicks, you can create an assessment.",
      image: "/simple.png",
      route: "/simple",
      minGroup: "basicuser"
    },
    {
      id: "INVOLVED_ASSESSMENT",
      title: "Involved Assessment Generation",
      description: "Do you have a more detailed idea of what you want for your assessment? This option offers more control over the specificity of your assessment.",
      image: "/complex.png",
      route: "/involved",
      minGroup: "user"
    },
    {
      id: "FILE_UPLOAD",
      title: "Assessments from Uploaded Content",
      description: "Create an assessment based on your own uploaded document. Perfect for tailoring assessments to specific course materials.",
      image: "/assess_upload.png",
      route: "/upload",
      minGroup: "poweruser"
    },
    {
      id: "MCQ_GENERATION",
      title: "Multiple Choice Questions",
      description: "Generate multiple-choice questions tailored to your specific topic and learning objectives. Perfect for quizzes and exams.",
      image: "/mcq.png",
      route: "/mcq",
      minGroup: "basicuser"
    },
    {
      id: "MCQ_UPLOAD",
      title: "MCQ from Uploaded Content",
      description: "Generate multiple-choice questions directly from your uploaded materials. This feature allows for more targeted and content-specific MCQ generation.",
      image: "/mcq_upload.png",
      route: "/mcq-upload",
      minGroup: "admin"
    },
    {
      id: "SHARED_ASSESSMENTS",
      title: "Shared Assessments & MCQs",
      description: "Review assessments that have been previously created and shared by users. You will also have the option to generate alternative assessments based on the existing ones.",
      image: "/list_assessments.png",
      route: "/assessment/listall",
      minGroup: "basicuser"
    }
  ];

  const getGroupColor = (minGroup) => {
    const groupColors = {
      basicuser: theme.palette.info.main,
      user: theme.palette.success.main,
      poweruser: theme.palette.warning.main,
      admin: theme.palette.error.main
    };
    return groupColors[minGroup] || theme.palette.primary.main;
  };

  const isFeatureAvailable = (minGroup) => {
    return hasPermission(minGroup);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Assessment Generator
        </Typography>
        <Paper 
          elevation={0} 
          sx={{ 
            p: 2, 
            bgcolor: 'background.default',
            borderRadius: 2,
            mb: 3
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Current Access Level: <Chip 
              label={user?.group || 'basicuser'} 
              color="primary" 
              size="small" 
              sx={{ ml: 1 }}
            />
          </Typography>
        </Paper>
      </Box>
      
      <Grid container spacing={3}>
        {cards.map((card) => {
          const isAvailable = isFeatureAvailable(card.minGroup);
          return (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: isAvailable ? 'translateY(-4px)' : 'none',
                    boxShadow: isAvailable ? 8 : 1,
                  },
                  opacity: isAvailable ? 1 : 0.6,
                  position: 'relative',
                  overflow: 'visible'
                }}
              >
                <Tooltip 
                  title={!isAvailable ? `Requires ${card.minGroup} access` : ''}
                  placement="top"
                >
                  <CardActionArea
                    onClick={() => isAvailable && navigate(card.route)}
                    disabled={!isAvailable}
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch'
                    }}
                  >
                    <Box sx={{ 
                      position: 'relative', 
                      width: '100%',
                      pt: 0,
                      overflow: 'hidden'
                    }}>
                      <CardMedia
                        component="img"
                        height="200"
                        image={card.image}
                        alt={card.title}
                        sx={{
                          filter: !isAvailable ? 'grayscale(50%)' : 'none',
                          display: 'block',
                          objectFit: 'cover',
                          width: '100%',
                          m: 0,
                          p: 0
                        }}
                      />
                    </Box>
                    <CardContent sx={{ flexGrow: 1, pt: 2 }}>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'flex-start', 
                        mb: 1 
                      }}>
                        <Typography gutterBottom variant="h6" component="h2">
                          {card.title}
                        </Typography>
                        <Chip
                          label={card.minGroup}
                          size="small"
                          sx={{
                            bgcolor: getGroupColor(card.minGroup),
                            color: 'white',
                            opacity: isAvailable ? 1 : 0.7
                          }}
                        />
                      </Box>
                      <Typography 
                        variant="body2" 
                        color={isAvailable ? "text.secondary" : "text.disabled"}
                      >
                        {card.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Tooltip>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}