import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  Alert,
  Tooltip,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Snackbar,
  Paper,
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css";
import MCQVerification from "./MCQVerification";
import config from "../config";

function MCQUploadMaster() {
  // File upload states
  const [fileState, setFileState] = useState({
    file: null,
    uploadStatus: "",
  });

  // MCQ generation states
  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState("medium");
  const [userLevels, setUserLevels] = useState([]);
  const [mainFields, setMainFields] = useState([]);
  const [selectedUserLevel, setSelectedUserLevel] = useState("");
  const [selectedMainField, setSelectedMainField] = useState("");
  const [topicField, setTopicField] = useState("");
  const [skillField, setSkillField] = useState("");
  const [taxonomyModels, setTaxonomyModels] = useState({});
  const [selectedTaxonomyModel, setSelectedTaxonomyModel] = useState("");
  const [modelInfo, setModelInfo] = useState("");

  // UI states
  const [loading, setLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showPrintButton, setShowPrintButton] = useState(false);
  const [messages, setMessages] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [showAnswers, setShowAnswers] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [fileId, setFileId] = useState(null);
  const [ws, setWs] = useState(null);

  // Dropzone configuration
  const onDrop = useCallback((acceptedFiles) => {
    setFileState({
      file: acceptedFiles[0],
      uploadStatus: "",
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  });

  // WebSocket setup
  useEffect(() => {
    const WS = new WebSocket(`${config.appUrl}/ws`);
    WS.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.status === "processing") {
        setMessages([data.message]);
      } else if (data.state === "Success") {
        setFileId(data.file_id);
        fetchMCQ(data.file_id);
        setLoading(false);
      } else if (data.state === "done") {
        setShowSaveButton(true);
        setShowPrintButton(true);
      }
      if (data.error) {
        console.error("Error:", data.error);
        setSnackbarMessage("Error processing request");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };
    setWs(WS);

    return () => {
      if (WS && WS.readyState === WebSocket.OPEN) {
        WS.close();
      }
    };
  }, []);

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();
      setUserLevels(data.user_level);
      setMainFields(data.main_field);
      setTaxonomyModels(data.taxonomies);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSnackbarMessage("Error fetching data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const fetchMCQ = async (id) => {
    try {
      const response = await fetch(`${config.webUrl}/mcq/${id}`);
      if (!response.ok) throw new Error("Failed to fetch MCQ data");
      const mcqData = await response.json();
      setQuestions(mcqData.questions);
    } catch (error) {
      console.error("Error fetching MCQ:", error);
      setSnackbarMessage("Error fetching MCQ data");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!fileState.file) {
      setSnackbarMessage("Please select a file to upload");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setMessages(["Processing file and generating MCQs..."]);

    const reader = new FileReader();
    reader.onload = async (e) => {
      const formData = {
        fileContent: e.target.result,
        fileName: fileState.file.name,
        numberOfQuestions,
        difficulty,
        userLevel: selectedUserLevel,
        mainField: selectedMainField,
        topic: topicField,
        skillField,
        taxonomyModel: selectedTaxonomyModel,
        formType: "mcq_upload",
      };

      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify(formData));
      } else {
        setSnackbarMessage("WebSocket connection not available");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setLoading(false);
      }
    };

    reader.readAsDataURL(fileState.file);
  };

  const handleSaveAndShare = async () => {
    if (!fileId) {
      setSnackbarMessage("No MCQs to save");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `${config.webUrl}/mcq/save-and-share/${fileId}`,
        {
          method: "POST",
        }
      );
      if (!response.ok) throw new Error("Failed to save MCQs");

      setSnackbarMessage("MCQs saved successfully");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error saving MCQs:", error);
      setSnackbarMessage("Failed to save MCQs");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const verifyQuestion = async (index) => {
    setVerificationStatus((prev) => ({
      ...prev,
      [index]: { status: "verifying" },
    }));
    try {
      const response = await fetch(`${config.webUrl}/verify-mcq-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: questions[index].question,
          selected_answer:
            questions[index].options[questions[index].correctAnswer],
        }),
      });
      const data = await response.json();
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: data.result,
          explanation: data.explanation,
        },
      }));
    } catch (error) {
      console.error("Error verifying question:", error);
      setVerificationStatus((prev) => ({
        ...prev,
        [index]: {
          status: "re-verify",
          explanation:
            "An error occurred during verification. Please try again.",
        },
      }));
    }
  };

  const handleNumberOfQuestionsChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setNumberOfQuestions(value);
    if (value > 10) {
      setModelInfo(
        "Generating more than 10 questions may take longer due to the verification and overlap checking process."
      );
    } else {
      setModelInfo("");
    }
  };

  const handlePrint = useCallback(() => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
            <html>
                <head>
                    <title>Print MCQs</title>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        .question { margin-bottom: 20px; }
                        .options { margin-left: 20px; }
                        .reason { font-style: italic; color: #666; }
                    </style>
                </head>
                <body>
                    ${questions
                      .map(
                        (q, index) => `
                        <div class="question">
                            <h3>${index + 1}. ${q.question}</h3>
                            <div class="options">
                                ${q.options
                                  .map(
                                    (option, i) => `
                                    <p>${String.fromCharCode(
                                      65 + i
                                    )}. ${option}</p>
                                `
                                  )
                                  .join("")}
                            </div>
                            <p><strong>Correct Answer:</strong> ${String.fromCharCode(
                              65 + q.correctAnswer
                            )}</p>
                            <p class="reason"><strong>Explanation:</strong> ${
                              q.reason
                            }</p>
                        </div>
                    `
                      )
                      .join("")}
                </body>
            </html>
        `);
    printWindow.document.close();
    printWindow.print();
  }, [questions]);

  const toggleAnswers = () => setShowAnswers(!showAnswers);

  const renderMessage = (content) => (
    <ReactMarkdown
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
      components={{
        p: ({ node, ...props }) => <Typography paragraph {...props} />,
        h3: ({ node, ...props }) => (
          <Typography variant="h6" gutterBottom {...props} />
        ),
        li: ({ node, ...props }) => (
          <li style={{ marginBottom: "10px" }} {...props} />
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{ maxWidth: "80%", margin: "0 auto" }}
    >
      <h1 className="text-3xl font-bold my-4">MCQ Generation from Documents</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Paper
              {...getRootProps()}
              elevation={3}
              className="uploadurfiles"
              sx={{
                padding: "20px",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: isDragActive ? "#f0f0f0" : "white",
                marginBottom: "20px",
              }}
            >
              <input {...getInputProps()} />
              <CloudUploadIcon sx={{ fontSize: 48, marginBottom: "10px" }} />
              <Typography variant="h6">
                {isDragActive
                  ? "Drop the file here"
                  : "Drag 'n' drop a file here, or click to select a file"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                (Only .pdf, .txt, and .docx files are accepted)
              </Typography>
            </Paper>

            {fileState.file && (
              <Typography variant="body1" sx={{ marginBottom: "20px" }}>
                Selected file: {fileState.file.name}
              </Typography>
            )}

            {/* Form fields from MCQMaster */}
            <FormControl fullWidth margin="normal">
              <Tooltip
                title="Select a Discipline. This field is searchable."
                placement="right"
              >
                <Autocomplete
                  options={mainFields}
                  getOptionLabel={(option) => option.optionText}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a Discipline" />
                  )}
                  value={
                    mainFields.find(
                      (option) => option.optionValue === selectedMainField
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setSelectedMainField(newValue ? newValue.optionValue : "");
                  }}
                />
              </Tooltip>
            </FormControl>

            {/* Select User Level */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="user-level-label">Level of study</InputLabel>
              <Select
                labelId="user-level-label"
                id="userLevels"
                value={selectedUserLevel}
                label="Select User Level"
                onChange={(e) => setSelectedUserLevel(e.target.value)}
              >
                {userLevels.map((item) => (
                  <MenuItem value={item.optionValue} key={item.optionValue}>
                    {item.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* TopicField */}
            {selectedMainField !== "other" &&
              selectedMainField !== "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="Topic_field"
                    label="Topic within the discipline (optional)"
                    variant="outlined"
                    value={topicField}
                    placeholder="e.g. Algebra, Geometry, etc."
                    onChange={(e) => setTopicField(e.target.value)}
                  />
                </FormControl>
              )}

            {/* Conditionally Rendered skills */}
            {selectedMainField !== "other" &&
              selectedMainField === "none" &&
              selectedMainField !== "" && (
                <FormControl fullWidth margin="normal">
                  <Tooltip
                    title="Enter specific skills, competencies, or specializations related to the assessment (optional)"
                    placement="right"
                  >
                    <TextField
                      id="skills_field"
                      label="Skills | Competencies | Specialization (optional)"
                      variant="outlined"
                      value={skillField}
                      placeholder="e.g. Data Analysis, Critical Thinking, etc."
                      onChange={(e) => setSkillField(e.target.value)}
                    />
                  </Tooltip>
                </FormControl>
              )}

            {/* Learning Taxonomy or Model Dropdown */}
            <FormControl fullWidth margin="normal">
              <InputLabel id="taxonomy-model-label">
                Learning Taxonomy or Model
              </InputLabel>
              <Tooltip
                title="Educational taxonomies are frameworks used to classify learning objectives and outcomes across different levels of complexity, from basic knowledge acquisition to advanced critical thinking skills."
                placement="right"
              >
                <Select
                  labelId="taxonomy-model-label"
                  id="taxonomyModels"
                  value={selectedTaxonomyModel}
                  label="Learning Taxonomy or Model"
                  onChange={(e) => setSelectedTaxonomyModel(e.target.value)}
                >
                  {Object.entries(taxonomyModels).map(([group, items]) => [
                    <MenuItem
                      value={group}
                      key={group}
                      disabled
                      style={{ fontWeight: "bold" }}
                    >
                      {group}
                    </MenuItem>,
                    ...items.map((item) => (
                      <MenuItem
                        value={item.optionValue}
                        key={item.optionValue}
                        style={{
                          paddingLeft: "20px",
                        }}
                      >
                        {item.optionText}
                      </MenuItem>
                    )),
                  ])}
                </Select>
              </Tooltip>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>Number of Questions</InputLabel>
              <Select
                value={numberOfQuestions}
                onChange={handleNumberOfQuestionsChange}
                label="Number of Questions"
                required
              >
                {[5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>level of difficulty</InputLabel>
              <Select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                label="level of difficulty"
                required
              >
                <MenuItem value="easy">Easy</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="hard">Hard</MenuItem>
              </Select>
            </FormControl>
            {modelInfo && (
              <Alert severity="info" style={{ marginTop: "10px" }}>
                {modelInfo}
              </Alert>
            )}

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || !fileState.file}
              sx={{ mt: 2 }}
            >
              Generate MCQs from Document
            </Button>
          </form>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="h5" component="h4">
              Upload your course materials or lecture notes to generate targeted
              multiple-choice questions. The system will analyze your document
              and create questions based on the content while considering your
              specified parameters such as difficulty level and learning
              taxonomy.
            </Typography>
          </div>
          {loading && (
            <Box>
              <div className="scene pt2">
                <div className="cube-wrapper">
                  <div className="cube">
                    <div className="cube-faces">
                      <div className="cube-face shadow"></div>
                      <div className="cube-face bottom"></div>
                      <div className="cube-face top"></div>
                      <div className="cube-face left"></div>
                      <div className="cube-face right"></div>
                      <div className="cube-face back"></div>
                      <div className="cube-face front"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div align="center">
                {messages.map((message, index) => (
                  <Typography className="pt" key={index}>
                    {message}
                  </Typography>
                ))}
              </div>
            </Box>
          )}
        </Grid>
      </Grid>

      {questions.length > 0 && (
        <Box mt={4}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleAnswers}
              startIcon={
                showAnswers ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              sx={{ ml: 1 }}
            >
              {showAnswers ? "Hide Answers" : "Show Answers"}
            </Button>
          </Box>

          {questions.map((q, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Question {index + 1} of {questions.length}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {renderMessage(q.question)}
                </Typography>
                <RadioGroup>
                  {q.options.map((option, i) => (
                    <FormControlLabel
                      key={i}
                      value={String(i)}
                      control={<Radio />}
                      label={renderMessage(option)}
                      disabled
                    />
                  ))}
                </RadioGroup>
                {showAnswers && (
                  <>
                    <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                      Correct Answer:{" "}
                      {String.fromCharCode(65 + q.correctAnswer)}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      Explanation: {renderMessage(q.reason)}
                    </Typography>
                  </>
                )}
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  <Tooltip title={verificationStatus[index]?.explanation || ""}>
                    <span>
                      <MCQVerification
                        status={verificationStatus[index]?.status}
                        onVerify={() => verifyQuestion(index)}
                      />
                    </span>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          ))}

          <Box mt={2} display="flex" gap={2}>
            {showSaveButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAndShare}
              >
                Save and Share
              </Button>
            )}
            {showPrintButton && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrint}
              >
                Print
              </Button>
            )}
          </Box>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MCQUploadMaster;
