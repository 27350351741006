import React from 'react';
import MainForm from './MainForm';
import InfoSection from './InfoSection';
import { Box,} from "@mui/material";

function AssignmentGenerator() {
    return (
        <Box justifyContent="center" alignItems="center" sx={{ maxWidth: '80%', margin: '0 auto' }}>
            <h1 className="text-3xl font-bold my-4">Assessment Partner</h1>
            <h3 className="text-xl mb-4">More involved assignment development</h3>
            <MainForm />
            <InfoSection />
        </Box>
    );
}

export default AssignmentGenerator;
