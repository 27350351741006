import React, { useContext } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  Container,
  useTheme,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import SchoolIcon from "@mui/icons-material/School";
import SpeedIcon from "@mui/icons-material/Speed";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { ThemeContext } from './ThemeContext';

function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { darkMode } = useContext(ThemeContext);

  const handleNavigate = (url) => {
    window.location.href = url;
  };

  const features = [
    {
      icon: (
        <AutoAwesomeIcon
          sx={{ fontSize: 40, color: theme.palette.primary.main }}
        />
      ),
      title: "AI-Powered Generation",
      description:
        "Advanced AI algorithms create tailored assessments aligned with your objectives",
    },
    {
      icon: (
        <SchoolIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      ),
      title: "Educational Best Practices",
      description:
        "Built on proven pedagogical frameworks and teaching methodologies",
    },
    {
      icon: (
        <SpeedIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      ),
      title: "Efficient Creation",
      description:
        "Reduce assessment creation time from hours to minutes while maintaining quality",
    },
  ];

  return (
    <Box sx={{ 
      // background: `linear-gradient(135deg, ${theme.palette.background.gradient.start} 0%, ${theme.palette.background.gradient.end} 100%)`,
      minHeight: '100vh'
    }}>
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Box
          sx={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            py: 8,
            position: 'relative',
          }}
        >
          {/* Decorative Elements */}
          <Box
            sx={{
              position: 'absolute',
              top: '10%',
              right: '5%',
              width: '200px',
              height: '200px',
              background: `radial-gradient(circle, ${theme.palette.primary.main}1A 0%, ${theme.palette.primary.main}00 70%)`,
              borderRadius: '50%',
              animation: 'pulse 4s infinite',
              '@keyframes pulse': {
                '0%': { transform: 'scale(1)' },
                '50%': { transform: 'scale(1.1)' },
                '100%': { transform: 'scale(1)' },
              },
            }}
          />

          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "2.5rem", md: "4.5rem" },
              fontWeight: 800,
              mb: 3,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              textShadow: darkMode 
                ? '0px 2px 4px rgba(0,0,0,0.5)'
                : '0px 2px 4px rgba(0,0,0,0.1)',
            }}
          >
            Assessment Partner
          </Typography>

          <Typography
            variant="h5"
            sx={{
              maxWidth: "800px",
              mb: 6,
              color: theme.palette.text.secondary,
              lineHeight: 1.8,
              fontSize: { xs: "1.1rem", md: "1.3rem" },
            }}
          >
            Transform your assessment creation process with our AI-powered
            platform. Designed for educators, by educators, to craft engaging and
            effective assessments that enhance learning outcomes.
          </Typography>

          <Box sx={{ mb: 6, display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'center' }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/select")}
              startIcon={<RocketLaunchIcon />}
              sx={{
                py: 2.5,
                px: 6,
                fontSize: "1.2rem",
                borderRadius: "50px",
                textTransform: "none",
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                boxShadow: darkMode
                  ? '0 4px 15px rgba(0,0,0,0.5)'
                  : '0 4px 15px rgba(25,118,210,0.3)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: darkMode
                    ? '0 6px 20px rgba(0,0,0,0.6)'
                    : '0 6px 20px rgba(25,118,210,0.4)',
                },
              }}
            >
              Access Edu Workspace
            </Button>
          </Box>

          {/* Modules Image with Animation */}
          <Paper
            elevation={4}
            sx={{
              p: 2,
              borderRadius: '16px',
            }}
            className='papercard'
          >
            <Box
              component="img"
              src="/Assessment_Partner_modules.png"
              alt="Assessment Partner Modules"
              sx={{
                width: "100%",
                maxWidth: "1000px",
                height: "auto",
                borderRadius: "12px",
              }}
            />
          </Paper>
        </Box>

        {/* Features Section */}
        <Box 
          sx={{ 
            py: 8,
            background: darkMode
              ? 'rgba(0,0,0,0.2)'
              : 'rgba(255,255,255,0.7)',
            borderRadius: '24px',
            backdropFilter: 'blur(10px)',
            mb: 8,
          }}
        >
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    p: 3,
                    height: '100%',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: '50%',
                      background: darkMode
                        ? 'rgba(25,118,210,0.2)'
                        : 'rgba(25,118,210,0.1)',
                      mb: 2,
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography 
                    variant="h6" 
                    sx={{ 
                      my: 2,
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Logos Section */}
        <Box
          sx={{
            py: 6,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 4,
          }}
        >
          <Box
            component="img"
            src="/macpherson-logo.png"
            alt="MacPherson Institute"
            sx={{
              width: 380,
              height: "auto",
              cursor: "pointer",
              opacity: 0.9,
              transition: "all 0.3s ease",
              filter: darkMode 
                ? 'grayscale(100%) brightness(0.8) invert(1)'
                : 'grayscale(100%)',
              '&:hover': { 
                opacity: 1,
                filter: darkMode
                  ? 'grayscale(0%) brightness(0.8) invert(1)'
                  : 'grayscale(0%)',
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => handleNavigate("https://mi.mcmaster.ca/")}
          />
          <Box
            component="img"
            src="/mcmaster_logo.png"
            alt="McMaster University"
            sx={{
              width: 200,
              height: "auto",
              cursor: "pointer",
              opacity: 0.9,
              transition: "all 0.3s ease",
              filter: darkMode
                ? 'grayscale(100%) brightness(0.8) invert(1)'
                : 'grayscale(100%)',
              '&:hover': { 
                opacity: 1,
                filter: darkMode
                  ? 'grayscale(0%) brightness(0.8) invert(1)'
                  : 'grayscale(0%)',
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => handleNavigate("https://mcmaster.ca")}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default LandingPage;
