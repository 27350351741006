import React from 'react';
import { Typography, Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './useAuth';

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Box sx={{ mb: 4 }}>
          <img 
            src="/dashboardImage.png" 
            alt="Dashboard Banner" 
            style={{ 
              width: '100%', 
              maxHeight: '300px', 
              objectFit: 'cover', 
              borderRadius: '8px' 
            }} 
          />
        </Box>
        <Typography variant="h4" component="h2" gutterBottom>
          Welcome back, {user.email}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/select')}
          sx={{ mt: 2 }}
        >
          Create New Assessment
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard;