import React, { useState, useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Collapse,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
  Home as HomeIcon,
  Create as CreateIcon,
  List as ListIcon,
  Info as InfoIcon,
  Feedback as FeedbackIcon,
  Dashboard as DashboardIcon,
  AdminPanelSettings as AdminIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  CloudUpload as CloudUploadIcon,
  Science as ScienceIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import { ThemeContext } from "./ThemeContext";

const drawerWidth = 240;

const SideMenu = ({ open, handleDrawerOpen, handleDrawerClose }) => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { darkMode } = useContext(ThemeContext);
  const theme = useTheme();
  const [experimentsOpen, setExperimentsOpen] = useState(false);

  const handleExperimentsClick = () => {
    setExperimentsOpen(!experimentsOpen);
  };

  // Update menu items based on authentication
  const menuItems = [
    { label: "Home", path: "/", icon: <HomeIcon /> },
    ...(user
      ? [
          { label: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
          { label: "Create", path: "/select", icon: <CreateIcon /> },
          { label: "Shared", path: "/assessment/listall", icon: <ListIcon /> },
        ]
      : []),
    { label: "Features", path: "/features", icon: <InfoIcon /> },
    { label: "Feedback", path: "/feedback", icon: <FeedbackIcon /> },
    ...(user?.group === "admin"
      ? [
          { label: "Admin Center", path: "/admin", icon: <AdminIcon /> },
          {
            label: "Experiments",
            icon: <ScienceIcon />,
            isExpandable: true,
            subItems: [
              {
                label: "Simple Form Flow",
                path: "/experiments/simple-form-flow",
              },
            ],
          },
        ]
      : []),
  ];

  const handleMenuItemClick = (path) => {
    if (path) {
      navigate(path);
      if (!user) {
        // Only close drawer for non-authenticated users
        handleDrawerClose();
      }
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: darkMode ? "#151515" : "#ffffff",
          color: darkMode ? "#ffffff" : "#000000",
        },
      }}
      variant={user ? "persistent" : "temporary"} // Change drawer variant based on auth state
      anchor="left"
      open={open}
      onClose={handleDrawerClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(0, 1),
          ...theme.mixins.toolbar,
          justifyContent: "flex-end",
        }}
      >
        {!user && ( // Only show close button for non-authenticated users
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }} />
          </IconButton>
        )}
      </Box>
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.label}>
            <ListItem
              button
              onClick={
                item.isExpandable
                  ? handleExperimentsClick
                  : () => handleMenuItemClick(item.path)
              }
            >
              <ListItemIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
              {item.isExpandable && (
                <>{experimentsOpen ? <ExpandLess /> : <ExpandMore />}</>
              )}
            </ListItem>
            {item.isExpandable && (
              <Collapse in={experimentsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem) => (
                    <ListItem
                      button
                      key={subItem.label}
                      onClick={() => handleMenuItemClick(subItem.path)}
                      sx={{ pl: 4 }}
                    >
                      <ListItemText primary={subItem.label} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
        {user ? (
          <ListItem button onClick={logout}>
            <ListItemIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        ) : (
          <ListItem button onClick={() => handleMenuItemClick("/login")}>
            <ListItemIcon sx={{ color: darkMode ? "#ffffff" : "#000000" }}>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default SideMenu;
