import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Typography,
  Paper,
} from "@mui/material";

const MCQCustomizationModal = ({ open, onClose, onSubmit, questionId }) => {
  const [mcqType, setMcqType] = useState("Standard MCQ");
  const [optionCount, setOptionCount] = useState(4);
  const [prompt, setPrompt] = useState("");

  const mcqTypeDescriptions = {
    "Standard MCQ":
      "Traditional multiple choice questions with one correct answer. Best for testing recall and basic understanding.",
    "Multiple Response":
      "Questions where more than one answer may be correct. Useful for complex topics with multiple valid answers.",
    "True/False":
      "Simple binary choice questions. Good for testing basic comprehension and clear facts.",
    "Best Answer":
      "Questions where all options may be partially correct, but one is clearly the best. Tests deeper understanding and judgment.",
    "Sequence MCQ":
      "Questions where the correct answer requires selecting items in the right order. Tests process understanding.",
    "Matrix MCQ":
      "Grid-based questions matching multiple items to their correct responses. Good for testing relationships and classifications.",
  };

  const handleSubmit = () => {
    const customizationData = {
      mcqType,
      optionCount: mcqType === "True/False" ? 2 : optionCount,
      prompt,
      questionId, // Include the questionId in the customization data
    };
    onSubmit(customizationData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Question Customization</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>MCQ Type</InputLabel>
            <Select
              value={mcqType}
              onChange={(e) => setMcqType(e.target.value)}
              label="MCQ Type"
            >
              <MenuItem value="Standard MCQ">Standard MCQ</MenuItem>
              <MenuItem value="Multiple Response">Multiple Response</MenuItem>
              <MenuItem value="True/False">True/False</MenuItem>
              <MenuItem value="Best Answer">Best Answer</MenuItem>
              <MenuItem value="Sequence MCQ">Sequence MCQ</MenuItem>
              <MenuItem value="Matrix MCQ">Matrix MCQ</MenuItem>
            </Select>
          </FormControl>

          <Paper
            elevation={0}
            sx={{
              p: 2,
              bgcolor: "primary.light",
              color: "primary.contrastText",
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Typography variant="body2">
              {mcqTypeDescriptions[mcqType]}
            </Typography>
          </Paper>

          {mcqType !== "True/False" && (
            <FormControl fullWidth>
              <InputLabel>Number of Options</InputLabel>
              <Select
                value={optionCount}
                onChange={(e) => setOptionCount(e.target.value)}
                label="Number of Options"
              >
                {[2, 3, 4, 5, 6].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Special Requirements"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            helperText="Add any specific requirements or modifications you'd like for this question"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MCQCustomizationModal;
