import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Autocomplete,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import config from "../config";
import MarkdownRenderer from "./MarkdownRenderer";
import ConfirmationModal from "./ConfirmationModal";
import { LoadingIndicator } from "./CustomHooks";

function MainForm() {
  const navigate = useNavigate();
  const resultContentsRef = useRef(null);
  const [ws, setWs] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Form data state
  const [formData, setFormData] = useState({
    customPrompts: [],
    userLevels: [],
    mainFields: [],
    assignmentGroups: {},
    TypeOfAssessments: [],
    taxonomyModels: {},
    assessmentFormats: {},
  });

  // Selected values state
  const [selectedValues, setSelectedValues] = useState({
    assessmentFormat: "",
    mainField: "",
    userLevel: "",
    typeOfAssessment: "",
    taxonomyModel: "",
    skillField: "",
    TopicField: "",
    genAiUse: "none",
    gptVersion: "gpt-4-1106-preview",
  });

  // UI state
  const [uiState, setUiState] = useState({
    messages: [],
    loading: false,
    resultContents: "",
    visibleContentLength: 0,
    showSaveButton: false,
    saveButtonDisabled: false,
    token: null,
    showPrintButton: false,
    showCustomizeButton: false,
    snackbarOpen: false,
    snackbarMessage: "",
    snackbarSeverity: "success",
    showConfirmationModal: false, // Add this line
  });

  // WebSocket message handler
  const handleWebSocketMessage = React.useCallback((event) => {
    const data = JSON.parse(event.data);

    switch (data.state) {
      case "queued":
        setUiState((prev) => ({
          ...prev,
          messages: [data.message],
          queuePosition: data.queuePosition,
          loading: true,
          isQueued: true,
        }));
        break;

      case "processing":
        const statusMessage =
          data.message ||
          (data.status === "processing" && data.message) ||
          "Processing...";
        setUiState((prev) => ({
          ...prev,
          messages: [statusMessage],
          loading: true,
          isQueued: false,
        }));
        break;

      case "Success":
        setUiState((prev) => ({
          ...prev,
          resultContents: data.content,
          loading: false,
          token: data.token,
          messages: [], // Clear the messages when done
        }));
        break;

      case "done":
        setUiState((prev) => ({
          ...prev,
          showSaveButton: true,
          showPrintButton: true,
          showCustomizeButton: true,
          loading: false,
          messages: [], // Clear the messages when done
        }));
        break;

      default:
        if (data.error) {
          console.error("Error:", data.error);
          setUiState((prev) => ({
            ...prev,
            snackbarMessage: "An error occurred",
            snackbarSeverity: "error",
            snackbarOpen: true,
            loading: false,
            messages: [],
          }));
        }
    }
  }, []);

  // Initialize WebSocket
  useEffect(() => {
    const initWebSocket = () => {
      const wsInstance = new WebSocket(`${config.appUrl}/ws`);

      wsInstance.onopen = () => {
        setIsConnected(true);
        setUiState((prev) => ({
          ...prev,
          error: null,
          snackbarOpen: false,
        }));
      };

      wsInstance.onmessage = handleWebSocketMessage;

      wsInstance.onclose = (event) => {
        setIsConnected(false);

        // Check if the closure was clean
        if (!event.wasClean) {
          setUiState((prev) => ({
            ...prev,
            loading: false,
            snackbarMessage: "Connection lost. Please try again.",
            snackbarSeverity: "error",
            snackbarOpen: true,
          }));
        }

        // Attempt to reconnect unless the page is being unloaded
        if (!window.isUnloading) {
          setTimeout(initWebSocket, 3000);
        }
      };

      wsInstance.onerror = (error) => {
        console.error("WebSocket error:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Connection error. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      };

      setWs(wsInstance);
    };

    initWebSocket();
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [handleWebSocketMessage]);

  useEffect(() => {
    window.isUnloading = false;

    const handleBeforeUnload = () => {
      window.isUnloading = true;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.isUnloading = true;
      if (ws) {
        ws.close();
      }
    };
  }, [ws]);

  // Scroll to bottom effect
  useEffect(() => {
    if (uiState.visibleContentLength > 0 && resultContentsRef.current) {
      const scrollToBottom = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const currentPosition = window.pageYOffset;
        const targetPosition = scrollHeight - window.innerHeight;
        const distance = targetPosition - currentPosition;
        const duration = 600; // Scroll animation duration in milliseconds

        const step = (timestamp) => {
          if (!start) start = timestamp;
          const progress = timestamp - start;
          const percentage = Math.min(progress / duration, 1);

          window.scrollTo(0, currentPosition + distance * percentage);

          if (progress < duration) {
            window.requestAnimationFrame(step);
          }
        };

        let start = null;
        window.requestAnimationFrame(step);
      };

      scrollToBottom();
    }
  }, [uiState.visibleContentLength]);

  // Content reveal animation
  useEffect(() => {
    if (uiState.resultContents.length > 20) {
      const revealSpeed = 12;
      const intervalId = setInterval(() => {
        setUiState((prev) => ({
          ...prev,
          visibleContentLength: Math.min(
            prev.visibleContentLength + revealSpeed,
            prev.resultContents.length
          ),
        }));
      }, 10);

      return () => clearInterval(intervalId);
    }
  }, [uiState.resultContents]);

  // Fetch initial data
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${config.webUrl}/api/data`);
      const data = await response.json();
      setFormData({
        userLevels: data.user_level,
        mainFields: data.main_field,
        TypeOfAssessments: data.type_of_assessment,
        assessmentFormats: {
          Written: data.assessment_formats.Written,
          Oral: data.assessment_formats.Oral,
          Practical: data.assessment_formats.Practical,
          Performance: data.assessment_formats.Performance,
          "Problem based": data.assessment_formats.Problem_based,
        },
        assignmentGroups: data.assignments,
        taxonomyModels: {
          "Blooms Taxonomy": data.taxonomies.blooms_taxonomy,
          "Solo Taxonomy": data.taxonomies.solo_taxonomy,
          "Creativity Taxonomy": data.taxonomies.creativity_taxonomy,
        },
        customPrompts: data.custom_prompts,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to fetch data",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Form submission handler
  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();

      if (!ws || !isConnected) {
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "No connection available. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
        return;
      }

      try {
        const formDataToSend = {
          ...selectedValues,
          formType: "advanced",
        };
        ws.send(JSON.stringify(formDataToSend));
        setUiState((prev) => ({ ...prev, loading: true }));
      } catch (error) {
        console.error("Error sending data:", error);
        setUiState((prev) => ({
          ...prev,
          loading: false,
          snackbarMessage: "Failed to send request. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    },
    [ws, isConnected, selectedValues]
  );

  // Save and share handler
  const handleSaveAndShare = useCallback(async () => {
    try {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: true }));
      const response = await fetch(`${config.webUrl}/assessments/save`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: uiState.token }),
      });

      if (!response.ok) throw new Error("Failed to save assessment");

      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Assessment saved successfully",
        snackbarSeverity: "success",
        snackbarOpen: true,
      }));
    } catch (error) {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Failed to save assessment",
        snackbarSeverity: "error",
        snackbarOpen: true,
      }));
    } finally {
      setUiState((prev) => ({ ...prev, saveButtonDisabled: false }));
    }
  }, [uiState.token]);

  // Customize handler
  const handleCustomize = useCallback(async () => {
    if (uiState.token) {
      try {
        await handleSaveAndShare();
        navigate(`/assessments/list/${uiState.token}`);
      } catch (error) {
        console.error("Error saving and navigating:", error);
        setUiState((prev) => ({
          ...prev,
          snackbarMessage: "Failed to save and customize. Please try again.",
          snackbarSeverity: "error",
          snackbarOpen: true,
        }));
      }
    } else {
      setUiState((prev) => ({
        ...prev,
        snackbarMessage: "Please generate an assessment first.",
        snackbarSeverity: "warning",
        snackbarOpen: true,
      }));
    }
  }, [uiState.token, handleSaveAndShare, navigate]);

  // Handle print
  const handlePrint = () => {
    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      MarkdownRenderer.getPrintContent(uiState.resultContents)
    );
    printWindow.document.close();
    printWindow.print();
  };

  // Field change handler
  const handleFieldChange = useCallback(
    (field) => (event, newValue) => {
      setSelectedValues((prev) => ({
        ...prev,
        [field]: newValue?.optionValue ?? event.target.value,
      }));
    },
    []
  );

  const LoadingComponent = useMemo(
    () => (
      <Box>
        {uiState.loading && <LoadingIndicator messages={uiState.messages} />}
      </Box>
    ),
    [uiState.loading, uiState.messages]
  );

  const handleGenerateClick = useCallback(
    (event) => {
      event.preventDefault();

      if (uiState.resultContents) {
        setUiState((prev) => ({
          ...prev,
          showConfirmationModal: true,
        }));
      } else {
        handleSubmit(event);
      }
    },
    [uiState.resultContents, handleSubmit]
  );

  const handleConfirmGenerate = useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showConfirmationModal: false,
      loading: false,
      messages: [],
      resultContents: "",
      visibleContentLength: 0,
      showSaveButton: false,
      showPrintButton: false,
      showCustomizeButton: false,
      token: null,
    }));

    handleSubmit({
      preventDefault: () => {},
    });
  }, [handleSubmit]);

  const handleCancelGenerate = useCallback(() => {
    setUiState((prev) => ({
      ...prev,
      showConfirmationModal: false,
    }));
  }, []);

  // Memoized form fields
  const renderFormFields = useMemo(
    () => (
      <>
        {/* Assessment Format */}
        <Tooltip title="Select the format of the assessment" placement="right">
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Assessment Format</InputLabel>
            <Select
              value={selectedValues.assessmentFormat}
              onChange={handleFieldChange("assessmentFormat")}
              MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
            >
              {Object.entries(formData.assessmentFormats).map(
                ([group, items]) => [
                  <MenuItem
                    key={group}
                    value={group}
                    disabled
                    style={{ fontWeight: "bold" }}
                  >
                    {group}
                  </MenuItem>,
                  ...items.map((item) => (
                    <MenuItem
                      key={item.optionValue}
                      value={item.optionValue}
                      style={{ paddingLeft: "20px" }}
                    >
                      {item.optionText}
                    </MenuItem>
                  )),
                ]
              )}
            </Select>
          </FormControl>
        </Tooltip>

        {/* Main Field Selection */}
        <FormControl fullWidth margin="normal">
          <Tooltip
            title="Select a Discipline. This field is searchable."
            placement="right"
          >
            <Autocomplete
              options={formData.mainFields}
              getOptionLabel={(option) => option.optionText}
              value={
                formData.mainFields.find(
                  (option) => option.optionValue === selectedValues.mainField
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedValues((prev) => ({
                  ...prev,
                  mainField: newValue ? newValue.optionValue : "",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select a Discipline" />
              )}
            />
          </Tooltip>
        </FormControl>

        {/* User Level Selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Level of study</InputLabel>
          <Select
            value={selectedValues.userLevel}
            onChange={handleFieldChange("userLevel")}
            label="Select User Level"
          >
            {formData.userLevels.map((item) => (
              <MenuItem value={item.optionValue} key={item.optionValue}>
                {item.optionText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Topic Field */}
        {selectedValues.mainField &&
          selectedValues.mainField !== "other" &&
          selectedValues.mainField !== "none" && (
            <FormControl fullWidth margin="normal">
              <TextField
                label="Topic within the discipline (optional)"
                variant="outlined"
                value={selectedValues.TopicField}
                placeholder="e.g. Algebra, Geometry, etc."
                onChange={handleFieldChange("TopicField")}
              />
            </FormControl>
          )}

        {/* Skills Field */}
        {selectedValues.mainField &&
          selectedValues.mainField !== "other" &&
          selectedValues.mainField !== "none" && (
            <FormControl fullWidth margin="normal">
              <Tooltip
                title="Enter specific skills, competencies, or specializations"
                placement="right"
              >
                <TextField
                  label="Skills | Competencies | Specialization (optional)"
                  variant="outlined"
                  value={selectedValues.skillField}
                  placeholder="e.g. Data Analysis, Critical Thinking, etc."
                  onChange={handleFieldChange("skillField")}
                />
              </Tooltip>
            </FormControl>
          )}

        {/* Type of Assessment */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Select a Type of Assessment</InputLabel>
          <Tooltip
            title={
              <React.Fragment>
                - Formative assessment evaluates during instruction for feedback
                <br />- Summative assessment measures against standards at
                completion
              </React.Fragment>
            }
            placement="right"
          >
            <Select
              value={selectedValues.typeOfAssessment}
              onChange={handleFieldChange("typeOfAssessment")}
              label="Select Formative or Summative"
            >
              {formData.TypeOfAssessments.map((item) => (
                <MenuItem value={item.optionValue} key={item.optionValue}>
                  {item.optionText}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        </FormControl>

        {/* Taxonomy Model */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Learning Taxonomy or Model</InputLabel>
          <Tooltip
            title="Educational taxonomy frameworks classify learning objectives across complexity levels"
            placement="right"
          >
            <Select
              value={selectedValues.taxonomyModel}
              onChange={handleFieldChange("taxonomyModel")}
              label="Learning Taxonomy or Model"
              MenuProps={{
                PaperProps: { style: { maxHeight: 300 } },
              }}
            >
              {Object.entries(formData.taxonomyModels).map(([group, items]) => [
                <MenuItem
                  key={group}
                  value={group}
                  disabled
                  style={{ fontWeight: "bold" }}
                >
                  {group}
                </MenuItem>,
                ...items.map((item) => (
                  <MenuItem
                    key={item.optionValue}
                    value={item.optionValue}
                    style={{ paddingLeft: "20px" }}
                  >
                    {item.optionText}
                  </MenuItem>
                )),
              ])}
            </Select>
          </Tooltip>
        </FormControl>

        {/* GenAI Usage */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Generative AI Usage</InputLabel>
          <Tooltip
            title="Select whether to incorporate generative AI in the assessment"
            placement="right"
          >
            <Select
              value={selectedValues.genAiUse}
              onChange={handleFieldChange("genAiUse")}
              label="Generative AI Usage"
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="usegenai">Incorporate Generative AI</MenuItem>
              <MenuItem value="deterai">Mitigate use of Generative AI</MenuItem>
            </Select>
          </Tooltip>
        </FormControl>

        {/* Hidden GPT Version */}
        <FormControl fullWidth margin="normal" style={{ display: "none" }}>
          <InputLabel>Select the version of LLM</InputLabel>
          <Select
            value={selectedValues.gptVersion}
            onChange={handleFieldChange("gptVersion")}
            label="Select the version of LLM"
          >
            <MenuItem value="gpt-4-1106-preview">GPT-4</MenuItem>
            <MenuItem value="gpt-3.5-turbo" disabled>
              GPT-3.5
            </MenuItem>
          </Select>
        </FormControl>
      </>
    ),
    [formData, selectedValues, handleFieldChange]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <form onSubmit={handleGenerateClick}>
            {renderFormFields}
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={uiState.loading}
              >
                Generate
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className="AssesmantGenMainInfo">
            <Typography variant="h5" component="h4">
              To create a customized assessment that aligns with your learning
              outcomes, begin by selecting one or more academic disciplines for
              targeted or interdisciplinary evaluation. Next, choose the student
              level (year 1-4, graduate) to adjust the complexity accordingly.
              Then, specify key topics, skills, and values to contextualize the
              assessment. Optionally, you may select a learning taxonomy and the
              level the assessment should target. Indicate whether the
              assessment is formative (process-focused) or summative
              (product-focused). Finally, choose whether the assessment should
              be tailored for students to utilize generative AI in completing
              the assignment or Design assessment to mitigate the use of AI
              generative tools.
            </Typography>
          </div>
          {LoadingComponent}
        </Grid>
      </Grid>

      <Box>
        <Box ref={resultContentsRef} mt={2} className="resultsBox">
          <MarkdownRenderer
            content={uiState.resultContents.slice(
              0,
              uiState.visibleContentLength
            )}
          />
        </Box>

        <Box mt={2} display="flex" gap={2}>
          {uiState.showSaveButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveAndShare}
              disabled={uiState.saveButtonDisabled}
            >
              Save and Share
            </Button>
          )}

          {uiState.showCustomizeButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCustomize}
              disabled={!uiState.token}
            >
              Customize
            </Button>
          )}

          {uiState.showPrintButton && (
            <Button variant="contained" color="secondary" onClick={handlePrint}>
              Print
            </Button>
          )}
        </Box>
      </Box>

      <Snackbar
        open={uiState.snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setUiState((prev) => ({ ...prev, snackbarOpen: false }))}
      >
        <Alert
          onClose={() =>
            setUiState((prev) => ({ ...prev, snackbarOpen: false }))
          }
          severity={uiState.snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {uiState.snackbarMessage}
        </Alert>
      </Snackbar>
      <ConfirmationModal
        open={uiState.showConfirmationModal}
        onConfirm={handleConfirmGenerate}
        onCancel={handleCancelGenerate}
      />
    </>
  );
}

export default MainForm;
