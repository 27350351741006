import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const FlexLoader = ({ message = "Thinking..." }) => (
  <Box
    sx={{
      position: "fixed",
      bottom: 20,
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      alignItems: "center",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "white",
      padding: "10px 20px",
      borderRadius: "20px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
      zIndex: 1000,
    }}
  >
    <CircularProgress size={24} sx={{ marginRight: 2, color: "white" }} />
    <Typography variant="body1" fontWeight="bold">
      {message}
    </Typography>
  </Box>
);

export default FlexLoader;