import { Box, Typography, CircularProgress } from '@mui/material';

export const LoadingIndicator = ({ messages = [] }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
      <CircularProgress />
      <Typography variant="h6" mt={2}>
        Processing request...
      </Typography>
      {messages.map((message, index) => (
        <Typography
          variant="body1"
          mt={2}
          textAlign="center"
          color="text.secondary"
          key={index}
          sx={{
            maxWidth: '600px',
            animation: 'fadeIn 0.5s ease-in'
          }}
        >
          {message}
        </Typography>
      ))}
    </Box>
  );
};
