// src/components/AboutUs.js
import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@mui/material';
import {
  AutoAwesome,
  Psychology,
  School,
  Speed,
  Engineering,
  Storage,
  Timeline,
  Build,
  GroupWork,
  Assessment as AssessmentIcon,
  Category,
  Analytics,
  LibraryBooks,
  Style,
  QuestionAnswer
} from '@mui/icons-material';

const AboutUs = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 6 }}>
        <Typography variant="h3" align="center" gutterBottom>
          About Assessment Partner
        </Typography>
        
        <Paper elevation={2} sx={{ p: 4, mt: 4 }} className='papercard'>
          {/* Introduction Section */}
          <Typography variant="body1" paragraph>
            Assessment Partner represents a collaboration between McMaster University and the MacPherson Institute. This platform combines educational expertise with artificial intelligence to transform assessment creation in higher education.
          </Typography>

          <Typography variant="body1" paragraph>
            Our platform serves as a bridge between traditional assessment methods and emerging AI technologies. We focus on creating assessments that maintain educational quality while reducing the time educators spend on assessment creation.
          </Typography>

          <Divider sx={{ my: 4 }} />

          {/* Core Technology Section */}
          <Typography variant="h5" gutterBottom>
            Core Technology
          </Typography>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemIcon><AutoAwesome /></ListItemIcon>
                  <ListItemText 
                    primary="Generative AI Integration" 
                    secondary="Our system uses advanced language models to generate unique, context-aware assessments that align with specific learning objectives."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Psychology /></ListItemIcon>
                  <ListItemText 
                    primary="Multi-Agent Architecture" 
                    secondary="Multiple specialized AI agents collaborate to ensure comprehensive assessment design, each focusing on specific aspects such as content, structure, and educational alignment."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><School /></ListItemIcon>
                  <ListItemText 
                    primary="Educational Framework Integration" 
                    secondary="Built-in support for established learning taxonomies and educational best practices guides the assessment creation process."
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemIcon><Speed /></ListItemIcon>
                  <ListItemText 
                    primary="Real-time Processing" 
                    secondary="Quick generation of assessments with immediate preview and editing capabilities."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Engineering /></ListItemIcon>
                  <ListItemText 
                    primary="Customization Engine" 
                    secondary="Flexible parameters allow educators to tailor assessments to specific course requirements and learning contexts."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Storage /></ListItemIcon>
                  <ListItemText 
                    primary="Knowledge Repository" 
                    secondary="Secure storage and sharing system for assessment templates and resources across the educational community."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Platform Capabilities Section */}
          <Typography variant="h5" gutterBottom>
            Platform Capabilities
          </Typography>
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemIcon><Timeline /></ListItemIcon>
                  <ListItemText 
                    primary="Assessment Development Paths" 
                    secondary="Multiple creation workflows from quick generation to detailed customization, supporting various assessment needs."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Build /></ListItemIcon>
                  <ListItemText 
                    primary="Customization Tools" 
                    secondary="Fine-tune assessment parameters including difficulty levels, question types, and learning outcome alignment."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><GroupWork /></ListItemIcon>
                  <ListItemText 
                    primary="Collaborative Features" 
                    secondary="Share and build upon existing assessments within your educational community."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><AssessmentIcon /></ListItemIcon>
                  <ListItemText 
                    primary="Assessment Types" 
                    secondary="Support for multiple assessment formats including written assignments, quizzes, problem-based assessments, and oral examination preparation."
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemIcon><Category /></ListItemIcon>
                  <ListItemText 
                    primary="Learning Taxonomy Integration" 
                    secondary="Alignment with educational frameworks ensures comprehensive coverage of learning objectives."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Analytics /></ListItemIcon>
                  <ListItemText 
                    primary="Analytics and Insights" 
                    secondary="Track assessment patterns and effectiveness through detailed analytics."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><LibraryBooks /></ListItemIcon>
                  <ListItemText 
                    primary="Content Library" 
                    secondary="Access to a growing repository of shared assessments and templates."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon><Style /></ListItemIcon>
                  <ListItemText 
                    primary="Flexible Formats" 
                    secondary="Generate assessments in various formats to suit different teaching and learning contexts."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Educational Impact Section */}
          <Typography variant="h5" gutterBottom>
            Educational Impact
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Typography variant="body1" paragraph>
              Assessment Partner transforms the assessment creation process while maintaining high educational standards. The platform supports educators in several key areas:
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Time Efficiency
                </Typography>
                <Typography variant="body2" paragraph>
                  Reduce assessment creation time from hours to minutes while maintaining quality and educational relevance. Our streamlined interface and AI assistance allow educators to focus more on teaching and less on administrative tasks.
                </Typography>
                
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Quality Assurance
                </Typography>
                <Typography variant="body2" paragraph>
                  Each generated assessment undergoes multiple validation checks to ensure alignment with learning objectives, appropriate difficulty levels, and clear communication.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Pedagogical Innovation
                </Typography>
                <Typography variant="body2" paragraph>
                  Access new assessment approaches and formats that promote deeper learning and engagement. The platform supports diverse assessment methods that cater to different learning styles and educational contexts.
                </Typography>
                
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Community and Collaboration
                </Typography>
                <Typography variant="body2" paragraph>
                  Join a community of educators sharing assessment resources and best practices. The platform facilitates knowledge exchange and collaborative improvement of assessment materials.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 4 }} />

          {/* Future Development */}
          <Typography variant="h5" gutterBottom>
            Future Development
          </Typography>
          <Typography variant="body1" paragraph>
            Assessment Partner continues to evolve with ongoing development in:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon><QuestionAnswer /></ListItemIcon>
              <ListItemText 
                primary="Advanced MCQ Generation" 
                secondary="Enhanced capabilities for creating sophisticated multiple-choice questions from uploaded course materials."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Analytics /></ListItemIcon>
              <ListItemText 
                primary="Learning Analytics Integration" 
                secondary="Advanced analytics tools to track assessment effectiveness and student learning patterns."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><GroupWork /></ListItemIcon>
              <ListItemText 
                primary="Expanded Collaboration Tools" 
                secondary="New features for team-based assessment creation and peer review processes."
              />
            </ListItem>
          </List>
        </Paper>
      </Box>
    </Container>
  );
};

export default AboutUs;