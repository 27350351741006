import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./components/useAuth";
import "./axiosConfig";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ThemeContextProvider } from "./components/ThemeContext";
import AssignmentGenerator from "./components/AssignmentGenerator";
import AssessmentList from "./components/AssessmentList";
import GeneratorSelector from "./components/GeneratorSelector";
import SimpleAssessmentGenerator from "./components/SimpleAssessmentGenerator";
import LandingPage from "./components/LandingPage";
import Features from "./components/Features";
import AssessmentDetail from "./components/AssessmentDetail";
import { CssBaseline, Box } from "@mui/material";
import MCQMaster from "./components/MCQMaster";
import MCQDetail from "./components/MCQDetail";
import Feedback from "./components/Feedback";
import LoginPage from "./components/LoginPage";
import CreateAccountPage from "./components/CreateAccountPage";
import AdminPanel from "./components/AdminPanel";
import Dashboard from "./components/Dashboard";
import SideMenu from "./components/SideMenu";
import CustomizeAssessment from "./components/CustomizeAssessment";
import FileUploadForm from "./components/FileUploadForm";
import MCQUploadMaster from "./components/MCQUploadMaster";
import Team from "./components/Team";
import AboutUs from "./components/AboutUs";
import UnauthorizedPage from "./components/UnauthorizedPage";
import SimpleFormFlow from "./components/SimpleFormFlow";
import IntroShowcase from "./components/IntroShowcase";
import AssignmentEnhancer from "./components/AssignmentEnhancer";
import "./App.css";
import "./assets/fonts/fonts.css";
import Disclaimer from "./components/Disclaimer";
import ResetPasswordPage from "./components/ResetPasswordPage";

const drawerWidth = 240;

const FeatureProtectedRoute = ({ children, requiredFeature, minGroup }) => {
  const { user, loading, hasPermission } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (minGroup && !hasPermission(minGroup)) {
    return <Navigate to="/unauthorized" replace />;
  }

  if (requiredFeature) {
    const userGroup = user.group || "basicuser";
    const featureFlags = {
      basicuser: {
        SIMPLE_ASSESSMENT: true,
        INVOLVED_ASSESSMENT: false,
        FILE_UPLOAD: false,
        MCQ_GENERATION: true,
        MCQ_UPLOAD: false,
        SHARED_ASSESSMENTS: true,
        ASSIGNMENT_ENHANCEMENT: true,
      },
      user: {
        SIMPLE_ASSESSMENT: true,
        INVOLVED_ASSESSMENT: true,
        FILE_UPLOAD: true,
        MCQ_GENERATION: true,
        MCQ_UPLOAD: true,
        SHARED_ASSESSMENTS: true,
        ASSIGNMENT_ENHANCEMENT: true,
      },
      poweruser: {
        SIMPLE_ASSESSMENT: true,
        INVOLVED_ASSESSMENT: true,
        FILE_UPLOAD: true,
        MCQ_GENERATION: true,
        MCQ_UPLOAD: true,
        SHARED_ASSESSMENTS: true,
        ASSIGNMENT_ENHANCEMENT: true,
      },
      admin: {
        SIMPLE_ASSESSMENT: true,
        INVOLVED_ASSESSMENT: true,
        FILE_UPLOAD: true,
        MCQ_GENERATION: true,
        MCQ_UPLOAD: true,
        SHARED_ASSESSMENTS: true,
        ASSIGNMENT_ENHANCEMENT: true,
      },
    };

    if (!featureFlags[userGroup]?.[requiredFeature]) {
      return <Navigate to="/unauthorized" replace />;
    }
  }

  return children;
};

const AppContent = () => {
  const { user } = useAuth();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeContextProvider>
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <CssBaseline />
          <Header open={open} toggleDrawer={toggleDrawer} />
          <Box sx={{ display: "flex", flex: 1 }}>
            <SideMenu
              open={open}
              handleDrawerOpen={() => setOpen(true)}
              handleDrawerClose={() => setOpen(false)}
              className="side-menu-main"
            />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                padding: 3,
                transition: (theme) =>
                  theme.transitions.create("margin", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                marginLeft: 0,
                ...(user && {
                  marginLeft: !open ? `-${drawerWidth}px` : 0,
                }),
                ...(open && {
                  transition: (theme) =>
                    theme.transitions.create("margin", {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                  marginLeft: 0,
                }),
              }}
            >
              <Box sx={{ marginTop: "64px" }}>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/unauthorized" element={<UnauthorizedPage />} />
                  <Route path="/showcase" element={<IntroShowcase />} />
                  <Route
                    path="/select"
                    element={
                      <FeatureProtectedRoute minGroup="basicuser">
                        <GeneratorSelector />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/involved"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="INVOLVED_ASSESSMENT"
                        minGroup="user"
                      >
                        <AssignmentGenerator />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/enhance"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="ASSIGNMENT_ENHANCEMENT"
                        minGroup="basicuser"
                      >
                        <AssignmentEnhancer />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/customize/:id"
                    element={
                      <FeatureProtectedRoute minGroup="basicuser">
                        <CustomizeAssessment />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/simple"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="SIMPLE_ASSESSMENT"
                        minGroup="basicuser"
                      >
                        <SimpleAssessmentGenerator />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/mcq"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="MCQ_GENERATION"
                        minGroup="basicuser"
                      >
                        <MCQMaster />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/mcq-upload"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="MCQ_UPLOAD"
                        minGroup="user"
                      >
                        <MCQUploadMaster />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/mcq/:id"
                    element={
                      <FeatureProtectedRoute minGroup="basicuser">
                        <MCQDetail />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route path="/mcqshared/:id" element={<MCQDetail />} />
                  <Route
                    path="/assessment/listall"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="SHARED_ASSESSMENTS"
                        minGroup="basicuser"
                      >
                        <AssessmentList />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/assessments/list/:id"
                    element={
                      <FeatureProtectedRoute minGroup="basicuser">
                        <AssessmentDetail />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route path="/features" element={<Features />} />
                  <Route path="/feedback" element={<Feedback />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/create-account"
                    element={<CreateAccountPage />}
                  />
                  <Route
                    path="/admin"
                    element={
                      <FeatureProtectedRoute minGroup="admin">
                        <AdminPanel />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <FeatureProtectedRoute minGroup="basicuser">
                        <Dashboard />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route
                    path="/upload"
                    element={
                      <FeatureProtectedRoute
                        requiredFeature="FILE_UPLOAD"
                        minGroup="user"
                      >
                        <FileUploadForm />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route path="/team" element={<Team />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route
                    path="/experiments/simple-form-flow"
                    element={
                      <FeatureProtectedRoute minGroup="admin">
                        <SimpleFormFlow />
                      </FeatureProtectedRoute>
                    }
                  />
                  <Route path="/disclaimer" element={<Disclaimer />} />
                  <Route path="/forgot-password" element={<ResetPasswordPage />} />
                  <Route path="/reset-password" element={<ResetPasswordPage />} />
                </Routes>
              </Box>
            </Box>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeContextProvider>
  );
};

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
