import React from 'react';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const QueueStatus = ({ queuePosition, message }) => {
  return (
    <Paper className="p-4 mb-4 bg-blue-50">
      <Box className="flex items-center gap-4">
        <AccessTimeIcon className="text-blue-500" />
        <Box className="flex-grow">
          <Typography className="font-medium text-blue-900">
            {message}
          </Typography>
          {queuePosition > 0 && (
            <Typography className="text-blue-700 text-sm mt-1">
              Queue position: {queuePosition}
            </Typography>
          )}
        </Box>
        <CircularProgress size={24} className="text-blue-500" />
      </Box>
    </Paper>
  );
};

export default QueueStatus;