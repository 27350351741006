// InfoSection.jsx
import React from 'react';

function InfoSection() {
    return (
        <div className="info-section">
            {/* Your static or dynamic content */}
        </div>
    );
}

export default InfoSection;
